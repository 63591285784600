import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../../contexts/StateContext';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import toast, { Toaster } from 'react-hot-toast';
import Education from '../../static/Education';
import {
  ACCESS_TOKEN,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  MOBILE_EXACT_LENGTH,
  NAME_MIN_LENGTH
} from "../../../config/Config";
import { register } from '../../../api/Api';
import { generateInvoice, retrieveUser } from "../../../api/commonApi";
import { ShowAlert } from '../../../components';
import { useNavigate } from 'react-router-dom';
const CareersRegister = ({props}) => {

  const location = useLocation();
  
  // const { subscriptionType, subscriptionFor, subscriptionCost,subscription} = location.state;// Ensure location.state is not null or undefined
  const { subscriptionType = '', subscriptionFor = '', subscriptionCost = '', subscription = {} } = location.state || {};


  //alert(subscriptionType+subscriptionFor);

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor,
  );

  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const c = useNavigate();
  const [registerRequest, setRegisterRequest] = useState({

    mobile: "",
    email: "",
    password: "",
    name: "",
    subscriptionFor: "",
    graduationYear: "",
    subscriptionType: "",
    subscriptionCost: "",


  });

  const [type, setType] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  let [passwordPattern, setPasswordPattern] = useState({
    number: false,
    lowerCase: false,
    upperCase: false,
    charsLength: false,
  });

  const [paymentData, setPaymentData] = useState(null);

  
const handleNavigate=()=>{
       navigate('/paymentGateway', { state: {
                  subscription: subscription,
                  paymentData:paymentData
                 
          }  
                })
}
  const updateName = (event) => {
    let req = registerRequest;
    req.name = event.target.value;
    setRegisterRequest(req);
  };

  const updateEmail = (event) => {
    let req = registerRequest;
    req.email = event.target.value;
    setRegisterRequest(req);
  };

  const updateMobile = (event) => {
    let req = registerRequest;
    req.mobile = event.target.value;
    setRegisterRequest(req);
  };

  const updateGraduation = (event) => {
    let req = registerRequest;
    req.graduationYear = event.target.value;
    setRegisterRequest(req);
  };


  // const updateSubscription = () => {
  //   //alert("register"+JSON.stringify(props.jobApplicationProps));
  //   const req = registerRequest;
  //   if (location.state) {
  //     req.subscriptionType = subscriptionType ? subscriptionType : '';
  //     req.subscriptionCost = subscriptionCost ? subscriptionCost : '';
  //     req.subscriptionFor = subscriptionFor ? subscriptionFor : '';
  //   }
  //   setRegisterRequest(req);
  //   //alert("register"+JSON.stringify(this.state.registerRequest));
  // };

  
  const updateSubscription = () => {
    const req = { ...registerRequest };
    if (location.state) {
      req.subscriptionType = subscriptionType || '';
      req.subscriptionCost = subscriptionCost || '';
      req.subscriptionFor = subscriptionFor || '';
    }
    setRegisterRequest(req);
  };
  useEffect(() => {
    document.title = "Subscribe";
    updateSubscription();
    //onShowAlert();
  }, []);


  const requestRegister = (event) => {

    event.preventDefault();
    updateSubscription();

    // const req = registerRequest;//3
    const req ={...registerRequest} ;
    //alert("success " + JSON.stringify(req.registerRequest));
    if (req.mobile.length !== MOBILE_EXACT_LENGTH) {
      //alert("success "+JSON.stringify(req.mobile));

      toast.error("Your mobile number must contain exactly " +
        MOBILE_EXACT_LENGTH +
        " and " +
        "no special characters. (+)");

      toast.error("error");


      return null;
    }
    if (req.name.length < NAME_MIN_LENGTH) {

      toast.error("Name length should be greater than " + NAME_MIN_LENGTH + ".");
      toast.error("error")

      return null;
    }

    if (
      req.password.length < PASSWORD_MIN_LENGTH ||
      req.password.length > PASSWORD_MAX_LENGTH
    ) {

      toast.error("Your password must contain between " +
        PASSWORD_MIN_LENGTH +
        " and " +
        PASSWORD_MAX_LENGTH +
        " characters !");

      toast.error("error");

      return null;
    }

    const passwordPattern = setPasswordPattern;
    if (
      passwordPattern.upperCase &&
      passwordPattern.lowerCase &&
      passwordPattern.charsLength &&
      passwordPattern.number
    ) {
      setShowLoading(false);
      // setShowPaymentModal(true)//add
      register(registerRequest)
        .then((res) => {

          // retrieveUser().then((res)=>{
          //   const subscription=res.data.subscription;
           
  
            
          //   if (subscription && subscription.paymentList && subscription.paymentList.length === 1) {
          //     const payment = subscription.paymentList[0];
          //     if (payment.paymentStatus !== "paymentReceived") {
          //       const paymentData = {
          //         projectRequirement: "",
          //         dueDate: payment.dueDate,
          //         paymentBillingFrom: payment.paymentBillingFrom,
          //         paymentBillingTo: payment.paymentBillingTo,
          //         totalBillingDay: payment.totalBillingDay,
          //         totalBillingHours: payment.totalBillingHours,
          //         costPerDay: subscription.subscriptionCost,
          //         subscriptionType: subscription.subscriptionType,
          //         subscriptionFor: subscription.subscriptionFor,
          //         costExcludingGstPay: payment.costExcludingGstPay,
          //         costIncludingGstPay: payment.costIncludingGstPay,
          //         costIncludingGstPayMinusTDS: payment.costIncludingGstPay - (payment.costExcludingGstPay / 10),
          //         paymentStatus: payment.paymentStatus,
          //         subscriptionId: subscription.subscriptionId,
          //         paymentId: payment.paymentId,
          //         userName: res.data.email,
          //         subscriptionName: subscription.subscriptionName,
          //         subscriptionCost: subscription.subscriptionCost,
          //         invoiceDate: payment.invoiceDate,
          //         paidSoFar: payment.paidSoFar,
          //         employeeEmailIdList: subscription.employeeEmailIdList,
          //         gst: Math.round(payment.costIncludingGstPay - payment.costExcludingGstPay)
          //       }
          //       setPaymentData(paymentData);
          //       navigate('/paymentGatewayNew', { state: { subscription, paymentData } });
          //     }
            
                
          //   }
            
          // })
        
          toast.success(res.message)
          toast.success("success")
          
        })
      

          
// paynow and paylater page //paymentgateway


// you have to send subscription data from res
// then construct and sent
// consturct login 
// const paymentData = {
//                     projectRequirement: "",
//                     dueDate: "",
//                     paymentBillingFrom: "",
//                     paymentBillingTo: "",
//                     totalBillingDay: "",
//                     totalBillingHours: "",
//                     costPerDay: "",
//                     subscriptionType: "",
//                     subscriptionFor: "",
//                     costExcludingGstPay: "",
//                     costIncludingGstPay: "",
//                     costIncludingGstPayMinusTDS:"",
//                     paymentStatus: "",
//                     subscriptionId: "",
//                     paymentId: "",
//                     userName: "",
//                     subscriptionId: "",
//                     subscriptionName: "",
//                     subscriptionCost: "",
//                     invoiceDate:"",
//                     paidSoFar:"",
//                     employeeEmailIdList: [],
//                     gst:"",
//                   };
//                   if(payment.paymentStatus!=="paymentReceived"){
//                   paymentData.subscriptionId = subscription.subscriptionId;
//                   paymentData.subscriptionCost =
//                   subscription.subscriptionCost;
//                   paymentData.employeeEmailIdList =
//                   subscription.employeeEmailIdList;
//                   paymentData.subscriptionFor =
//                   subscription.subscriptionFor;
//                   paymentData.subscriptionType =
//                    subscription.subscriptionType;
//                   paymentData.subscriptionName =
//                     c.subscriptionName;
//                   paymentData.subscriptionId = subscription.subscriptionId;
//                   paymentData.projectRequirement =
//                     subscription.projectRequirement;
//                   paymentData.costPerDay = subscription.subscriptionCost;
//                   paymentData.dueDate = payment.dueDate;
//                   paymentData.paidSoFar= payment.paidSoFar;
//                   paymentData.invoiceDate = payment.invoiceDate;
//                   paymentData.paymentBillingFrom = payment.paymentBillingFrom;
//                   paymentData.paymentBillingTo = payment.paymentBillingTo;
//                   paymentData.costExcludingGstPay =
//                     payment.costExcludingGstPay;
//                   paymentData.costIncludingGstPay =
//                     payment.costIncludingGstPay;
//                     paymentData.gst=Math.round(payment.costIncludingGstPay-payment.costExcludingGstPay);
//                     paymentData.costIncludingGstPayMinusTDS =Math.round(payment.costIncludingGstPay-(payment.costExcludingGstPay/10));
//                   paymentData.paymentId = payment.paymentId;
//                   paymentData.totalBillingDay = payment.totalBillingDay;
//                   paymentData.totalBillingHours = payment.totalBillingHours;
//                   paymentData.paymentStatus = payment.paymentStatus;
//                   paymentData.userName = userName;
//                   constructPaymentDataList.push(paymentData)

        
        .catch((error) => {
          if (error.message) {

            toast.error(error.message)
            toast.error("error")

          }
          else {

            toast.error("Sorry! Something went wrong. Please try again!")
            toast.error("error")

          }
          setShowLoading(false);
          //console.log(error);
        });
    } else {

      toast.error("Invalid password : please take care of password constraints !")
      toast.error("error")



    }
  };

  const showPassword = () => setType(!type);

  const nextStep = () => {
    setStep(step + 1);
    setEmail('');
  }

  const prevStep = () => {
    setStep(step - 1);
    setError('');
  };

  let updatePassword = (event) => {
    let req = { ...registerRequest };
    req.password = event.target.value;
    setRegisterRequest(req);

    let passwordPattern = setPasswordPattern;

    const lowerCase = /[a-z]/g;
    if (req.password.match(lowerCase)) {
      document.getElementById("lowerCase").classList.remove("text-danger");
      document.getElementById("lowerCase").classList.add("text-success");
      passwordPattern.lowerCase = true;
    } else {
      document.getElementById("lowerCase").classList.remove("text-success");
      document.getElementById("lowerCase").classList.add("text-danger");
      passwordPattern.lowerCase = false;
    }

    const upperCase = /[A-Z]/g;
    if (req.password.match(upperCase)) {
      document.getElementById("upperCase").classList.remove("text-danger");
      document.getElementById("upperCase").classList.add("text-success");
      passwordPattern.upperCase = true;
    } else {
      document.getElementById("upperCase").classList.remove("text-success");
      document.getElementById("upperCase").classList.add("text-danger");
      passwordPattern.lowerCase = false;
    }

    const number = /[0-9]/g;
    if (req.password.match(number)) {
      document.getElementById("number").classList.remove("text-danger");
      document.getElementById("number").classList.add("text-success");
      passwordPattern.number = true;
    } else {
      document.getElementById("number").classList.remove("text-success");
      document.getElementById("number").classList.add("text-danger");
      passwordPattern.number = false;
    }

    if (
      req.password.length > PASSWORD_MIN_LENGTH &&
      req.password.length < PASSWORD_MAX_LENGTH
    ) {
      document.getElementById("chars").classList.remove("text-danger");
      document.getElementById("chars").classList.add("text-success");
      passwordPattern.charsLength = true;
    } else {
      document.getElementById("chars").classList.remove("text-success");
      document.getElementById("chars").classList.add("text-danger");
      passwordPattern.charsLength = false;
    }
    setPasswordPattern(passwordPattern);
  };


  const [step, setStep] = useState(1);
  
  const [email, setEmail] = useState('');
  
  const [error, setError] = useState('');


 
  if (localStorage.getItem(ACCESS_TOKEN)) {


    toast.success("You are already logged in !")
    //history.push("/");
    return <p>Oops ! Your are already logged in !</p>;
  } else {


    return (
      <div className="container mx-auto p-4">
          {/* <div
            className={
              currentMode === "Dark"
                ? "max-w-md mx-auto p-8 rounded bg-dark-gradient-r"
                : "max-w-md mx-auto p-8 rounded bg-light-gradient-r"
            }
          > */}
          <div className="max-w-md mx-auto p-8 rounded bg-white">
        <form onSubmit={(e) => requestRegister(e)}>
              {showLoading ? (
                // <div className="align-content-center text-center">
                //   <h5>Signing up</h5>
                //   <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                //     refresh
                //   </i>
                // </div>
                <div className="flex justify-center items-center my-5">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-200 rounded-full animate-spin" style={{ borderTopColor: currentColor }}></div>
  </div>
              ) : (
                <div className='card card-signin my-5 -mt-10'>


                  <div className="card-body ml-5 mr-5 mt-5">
                    <h1 className="text-xl uppercase flex justify-center font-semibold mb-2">
                      Sign Up
                    </h1>
                  </div>

                  <p className='text-base flex justify-center mb-2'>Welcome Back! Please Enter your details.</p>

                  <br />
                  {step === 1 && (

                    <div>
                      <h2 className="font-semibold">Name </h2>
                      <input
                        type="text"
                        className="form-control w-full text-base py-2 my-4 bg-transparent
            text-black border-b border-black outline-none focus:outline-none"
                        placeholder="Enter your name"

                        onChange={updateName}
                        autoComplete="on"
                        required
                      />

                      <h2 className="font-semibold">Email address </h2>
                      <input
                        type="email"
                        className="form-control w-full text-base py-2 my-4 bg-transparent
               text-black border-b border-black outline-none focus:outline-none"
                        placeholder="Enter your email address"
                        onChange={updateEmail}
                        autoComplete="on"
                        required
                      />


                      <h2 className="font-semibold">Mobile </h2>
                      <input
                        type="number"
                        className="form-control w-full text-base py-2 my-4 bg-transparent
               text-black border-b border-black outline-none focus:outline-none"
                        placeholder="Enter your phone number"

                        onChange={updateMobile}
                        autoComplete="on"
                        required
                      />


                      <button onClick={nextStep} className=" w-20 text-white p-2 rounded" style={{ backgroundColor: currentColor }} >
                        Next
                      </button>
                    </div>
                  )}
                  {step === 2 && (
                    <div>
                      <h2 className="font-semibold">Graduation Year</h2>
                      <input
                        type="text"
                        className="form-control w-full text-base py-2 my-4 bg-transparent
               text-black border-b border-black outline-none focus:outline-none"
                        placeholder="Enter your graduation year"

                        onChange={updateGraduation}
                        autoComplete="on"
                        required

                      />


                      <h2 className="font-semibold">Password</h2>
                      <div className="flex items-center relative">
                        <input
                          type={type ? "text" : "password"}
                          className="form-control w-full text-base py-2 my-4 bg-transparent
                 text-black border-b border-black outline-none focus:outline-none"
                          placeholder="Password"
                          onChange={updatePassword}
                          autoComplete="on"
                          required
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                        />

                        <span
                          className="showpass absolute right-2 text-2xl cursor-pointer"
                          onClick={showPassword} >
                          {type ? (
                            <AiFillEye />
                          ) : (
                            <AiFillEyeInvisible />
                          )}
                        </span>

                      </div>
                      <small className="text-muted">
                        Your password must contain : <a className="text-danger" id="number">
                          * One number
                        </a>
                        <a className="text-danger" id="lowerCase">
                          * One lower case
                        </a>
                        <a className="text-danger" id="upperCase">
                          * One upper case
                        </a>
                        <a className="text-danger" id="chars">
                          * Between 6 and 20 chars
                        </a>
                      </small>

                      {/* <h2 className="text-base">Confirm Password</h2>          
               <input
                 type="password"
                 className="form-control w-full text-base py-2 my-4 bg-transparent
                 text-black border-b border-black outline-none focus:outline-none"
                 placeholder="Confirm Password"
                 value={confirmPassword}
                 onChange={(e) => setConfirmPassword(e.target.value)}                
                 maxLength={maxPasswordLength}
                 autoComplete="on"             
                 required
   
               /> */}

                      <div className="w-full flex justify-between" style={{ color: currentColor }}>
                        <p>Already Registered ?

                        </p>
                        <div>
                          <Link to="/login">
                            <p className="text-black text-xl">
                              Login now !
                            </p>

                          </Link>
                        </div>
                      </div>

                      {error && <p className="text-red-500 mb-4">{error}</p>}
                      <div className='flex justify-between'>
                        <button onClick={prevStep} className=" text-white w-20 mt-3 p-2 rounde" style={{ backgroundColor: currentColor }}>
                          Back
                        </button>

                        <button className=" text-white mt-3 p-2 rounded" style={{ backgroundColor: currentColor }} >
                          Create Account 
                        </button>

                      </div>
                      <p className="py-2 mt-5">
                        <span style={{ color: currentColor }}>By clicking "Create account" or "Continue with Google", you agree to the</span> <span>YaazhTech Software Development Lab</span>
                        <span style={{ color: currentColor }}> and</span> <span>Privacy Policy. </span>
                        <span style={{ color: currentColor }}>We'll never share your information with anyone else.</span>
                      </p>


                    </div>

                  )}



                </div>
              )}
            

          

        </form>
       

         </div>
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            error: {
              duration: 6000,
              style: {
                background: '#FF4136',
                color: '#fff',
              },
            },

            success: {
              duration: 6000,
              style: {
                background: '#008000',
                color: '#fff',
              },
            },
          }}
        />

      </div>
    )
  }
}
export default CareersRegister;