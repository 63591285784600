import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { RiNotification3Line } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useContextSelector } from "use-context-selector";
import { Cart, Chat, Notification, UserProfile } from ".";
import avatar from "../assets/images/logos/logoFinal30x30.png";
import { RiHomeWifiFill } from "react-icons/ri";
import { FcAbout } from "react-icons/fc";
import { GrServices } from "react-icons/gr";
import { IoBriefcaseOutline } from "react-icons/io5";
import { BiSolidContact } from "react-icons/bi";
import { StateContext } from "../contexts/StateContext";
import { retrieveProfileImage } from "../api/EmployeeApi";
import { AiFillCaretDown } from "react-icons/ai";
import { Dropdown } from "reactstrap";
import LanguageSelector from "./LanguageSelector";
import Navigation from "./Navigation";

function NavButton({ title, customFunc, icon, color, dotColor }) {
  return (
    <TooltipComponent content={title} position="BottomCenter">
      <button
        type="button"
        onClick={() => customFunc()}
        style={{ color }}
        className="relative text-xl rounded-full p-3 hover:bg-light-gray"
      >
        <span
          style={{ background: dotColor }}
          className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
        />
        {icon}
      </button>
    </TooltipComponent>
  );
}

function Navbar() {
  const activeMenu = useContextSelector(
    StateContext,
    (state) => state.activeMenu
  );

  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );

  const setActiveMenu = useContextSelector(
    StateContext,
    (state) => state.setActiveMenu
  );
  const handleClick = useContextSelector(
    StateContext,
    (state) => state.handleClick
  );
  const isClicked = useContextSelector(
    StateContext,
    (state) => state.isClicked
  );

  const setScreenSize = useContextSelector(
    StateContext,
    (state) => state.setScreenSize
  );
  const screenSize = useContextSelector(
    StateContext,
    (state) => state.screenSize
  );
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  //const setIsLoggedIn = useContextSelector(StateContext, (state) => state.setIsLoggedIn);
  const [showLoading, setShowLoading] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const retrieveProfilePic = () => {
    setShowLoading(true);
    retrieveProfileImage()
      .then((res) => {
        setImagePreviewUrl(res.data);
        //toast("Profile image retrieved successfully");
        setShowLoading(true);
      })
      .catch((error) => {
        if (error.status === 401) {
          //showAlert("Your profile image does't exist !", "error");
        } else {
          //showAlert(error.message, "error");
        }
        setShowLoading(true);
      });
  };
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);
    if (isLoggedIn) {
      retrieveProfilePic();
    }
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const handleActiveMenu = () => setActiveMenu(!activeMenu);
  function handleToggle() {
    setOpen(true);
  }
  

  //  const [open, setOpen] = useState(false);

  const [servicesOpen, setServicesOpen] = useState(false);
  const [careersOpen, setCareersOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const toggleServices = () => {
    setServicesOpen(!servicesOpen);
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const toggleCareers = () => {
    setCareersOpen(!careersOpen);
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const toggleAbout = () => {
    setAboutOpen(!aboutOpen);
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const showServices = () => {
    setServicesOpen(true);
  };

  const hideServices = () => {
    setServicesOpen(false);
  };
  const showCareers = () => {
    setCareersOpen(true);
  };

  const hideCareers = () => {
    setCareersOpen(false);
  };

  const showAbout = () => {
    setAboutOpen(true);
  };

  const hideAbout = () => {
    setAboutOpen(false);
  };

  const [subMenuOpen, setSubMenuOpen] = useState({});
  const [open, setOpen] = useState(false);

  const toggleSubMenu = (index) => {
    setSubMenuOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const toggleClose = () => {
    setOpen(false);
    setAboutOpen(false);
    setCareersOpen(false);
    setServicesOpen(false);
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <div
        className={
          currentMode === "Dark"
            ? "bg-[#f5f5f5]"
            : "bg-[#f5f5f5]"
        }
      >
        <div className="flex justify-between  p-2  relative">
          <div className="mt-6">
            <NavButton
              title="Menu"
              customFunc={handleActiveMenu}
              color={currentColor}
              icon={<AiOutlineMenu />}
            />
          </div>
          {isLoggedIn ? (
            <div className="flex">
              {/* <NavButton
            title="Cart"
            customFunc={() => handleClick("cart")}
            color={currentColor}
            icon={<FiShoppingCart />}
          /> */}
              {/* <NavButton
            title="Chat"
            dotColor="#03C9D7"
            customFunc={() => handleClick("chat")}
            color={currentColor}
            icon={<BsChatLeft />}
          /> */}
              <NavButton
                title="Notification"
                dotColor="rgb(254, 201, 15)"
                customFunc={() => handleClick("notification")}
                color={currentColor}
                icon={<RiNotification3Line />}
              />
              <TooltipComponent content="Profile" position="BottomCenter">
                <div
                  className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                  onClick={() => handleClick("userProfile")}
                >
                  {imagePreviewUrl ? (
                    <img
                      src={`data:image/jpeg;base64,${imagePreviewUrl}`}
                      alt="avatar"
                      className="rounded-full h-8 w-8"
                    />
                  ) : (
                    <img
                      src={avatar}
                      alt="avatar"
                      className="rounded-full h-8 w-8"
                    />
                  )}
                  {/** <p>
              <span className="text-gray-400 text-14">Hi,</span>{' '}
              <span className="text-gray-400 font-bold ml-1 text-14">
                Michael
              </span>
            </p> */}
                  <MdKeyboardArrowDown className="text-gray-400 text-14" />
                </div>
              </TooltipComponent>

              {isClicked.cart && <Cart />}
              {isClicked.chat && <Chat />}
              {isClicked.notification && <Notification />}
              {isClicked.userProfile && <UserProfile />}
            </div>
          ) : (
            <>
            <Navigation/>
            {/* <div className="flex items-center font-medium justify-between">
              <div className="z-50 p-5 md:w-auto w-full flex justify-between">
                <div className="md:hidden ml-20 -mt-1">
                  <Link
                    to="/login"
                    className="ml-10"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    <button
                      className="-mt-2 w-50 rounded-full px-7 pb-2.5 pt-3 text-sm text-white"
                      style={{ backgroundColor: currentColor }}
                    >
                      <span>Login</span>
                    </button>
                  </Link>
                </div>
                <div className="text-3xl ml-10 -mt-2 md:hidden">
                  {open ? (
                    <>
                      <button
                        className={`p-2 ${open ? "block" : "hidden"} rounded-lg lg:hidden text-blue-900`}
                        onClick={() =>
                          toggleClose()
                        }
                      >
                        <svg
                          className="h-6 w-6 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                          />
                        </svg>
                      </button>
                      <ul
                        className={`md:hidden absolute w-full h-full bottom-0 py-24pl-4 duration-500 ${
                          open ? "right-0" : "right-[-100%]"
                        }`}
                      >
                        <li
                          className={
                            currentMode === "Dark"
                              ? "bg-dark-gradient-r flex flex-col absolute left-0 top-16 font-semibold text-2xl text-center pt-8 pb-10 gap-2 w-full h-screen sm:h-auto transition-transform duration-300"
                              : "bg-light-gradient-r flex flex-col absolute left-0 top-16 font-semibold text-2xl text-center pt-8 pb-10 gap-2 w-full h-screen sm:h-auto transition-transform duration-300"
                          }
                        >
                          <div className=" h-screen flex items-center justify-center ml-10 ">
                            <div
                              className={` ${
                                open ? "w-fit px-2 " : "w-0 "
                              } lg:w-fit h-screen  md:h-full md:w-48 relative duration-500`}
                            >
                              <div className=" justify-center mt-3">
                                <ul className="pt-6 mt-20 flex flex-col items-center -ml-3">
                                  {Menus.map((Menu, index) => (
                                    <>
                                      {Menu.title === "About" ? (
                                        <>
                                          <li
                                            className={`flex  rounded-md p-2 cursor-pointer  text-black text-xl items-center gap-x-4 ${
                                              Menu.gap ? "mt-9" : "mt-2"
                                            } `}
                                          >
                                            <Dropdown
                                              onClick={() =>
                                                setAboutOpen(!aboutOpen)
                                              }
                                              className={`flex items-center w-full justify-center ${
                                                Menu.customClass
                                                  ? Menu.customClass
                                                  : ""
                                              }`}
                                            >
                                              {Menu.icon && (
                                                <span className="mr-2">
                                                  {Menu.icon}
                                                </span>
                                              )}
                                              <span className="text-2xl hover:bg-[#eccaff] p-2">
                                                {Menu.title}
                                              </span>
                                            </Dropdown>
                                            {Menu.subMenus && (
                                              <AiFillCaretDown
                                                style={{
                                                  verticalAlign: "middle",
                                                  marginRight: "1px",
                                                  marginLeft: "-12px",
                                                }}
                                                onClick={() =>
                                                  setAboutOpen(!aboutOpen)
                                                }
                                                className={`${
                                                  aboutOpen && "rotate-180"
                                                }`}
                                              />
                                            )}
                                          </li>
                                          {Menu.subMenus &&
                                            aboutOpen &&
                                            open && (
                                              <ul>
                                                {Menu.subMenus.map(
                                                  (subMenuItem, idx) => (
                                                    <li
                                                      key={idx}
                                                      className="flex px-2 cursor-pointer text-xs text-center text-black font-semibold py-1"
                                                      style={{
                                                        listStyle: "none",
                                                      }}
                                                    >
                                                      <Link
                                                        to={subMenuItem.linkTo}
                                                        onClick={() =>
                                                          toggleClose()
                                                        }
                                                        className="hover:underline"
                                                        style={{
                                                          textDecoration:
                                                            "none",
                                                          borderBottom:
                                                            "1px solid transparent",
                                                          display: "block",
                                                          padding: "8px 10px",
                                                          transition:
                                                            "all 0.3s",
                                                        }}
                                                        onMouseEnter={(e) => {
                                                          e.target.style.borderBottom =
                                                            "3px solid black";
                                                        }}
                                                        onMouseLeave={(e) => {
                                                          e.target.style.borderBottom =
                                                            "1px solid transparent";
                                                        }}
                                                      >
                                                        {subMenuItem.title}
                                                      </Link>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                        </>
                                      ) : (
                                        <>
                                          {Menu.title === "Services" ? (
                                            <>
                                              <li
                                                className={`flex  rounded-md p-2 cursor-pointer  text-black text-xl items-center gap-x-4 ${
                                                  Menu.gap ? "mt-9" : "mt-2"
                                                } `}
                                              >
                                                <Dropdown
                                                  to={Menu.src}
                                                  onClick={() =>
                                                    setServicesOpen(
                                                      !servicesOpen
                                                    )
                                                  }
                                                  className={`flex items-center w-full justify-center ${
                                                    Menu.customClass
                                                      ? Menu.customClass
                                                      : ""
                                                  }`}
                                                >
                                                  {Menu.icon && (
                                                    <span className="mr-2">
                                                      {Menu.icon}
                                                    </span>
                                                  )}
                                                  <span className="text-2xl hover:bg-[#eccaff] p-2">
                                                    {Menu.title}
                                                  </span>
                                                </Dropdown>
                                                {Menu.subMenus && (
                                                  <AiFillCaretDown
                                                    style={{
                                                      verticalAlign: "middle",
                                                      marginRight: "1px",
                                                      marginLeft: "-12px",
                                                    }}
                                                    onClick={() =>
                                                      setServicesOpen(
                                                        !servicesOpen
                                                      )
                                                    }
                                                    className={`${
                                                      servicesOpen &&
                                                      "rotate-180"
                                                    }`}
                                                  />
                                                )}
                                              </li>
                                              {Menu.subMenus &&
                                                servicesOpen &&
                                                open && (
                                                  <ul>
                                                    {Menu.subMenus.map(
                                                      (subMenuItem, idx) => (
                                                        <li
                                                          key={idx}
                                                          className="flex px-2 cursor-pointer text-xs text-center text-black font-semibold py-1"
                                                          style={{
                                                            listStyle: "none",
                                                          }}
                                                        >
                                                          <Link
                                                            to={
                                                              subMenuItem.linkTo
                                                            }
                                                            onClick={() =>
                                                              toggleClose()
                                                            }
                                                            className="hover:underline"
                                                            style={{
                                                              textDecoration:
                                                                "none",
                                                              borderBottom:
                                                                "1px solid transparent",
                                                              display: "block",
                                                              padding:
                                                                "8px 10px",
                                                              transition:
                                                                "all 0.3s",
                                                            }}
                                                            onMouseEnter={(
                                                              e
                                                            ) => {
                                                              e.target.style.borderBottom =
                                                                "3px solid black";
                                                            }}
                                                            onMouseLeave={(
                                                              e
                                                            ) => {
                                                              e.target.style.borderBottom =
                                                                "1px solid transparent";
                                                            }}
                                                          >
                                                            {subMenuItem.title}
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                )}
                                            </>
                                          ) : (
                                            <>
                                              {Menu.title === "Careers" ? (
                                                <>
                                                  <li
                                                    className={`flex  rounded-md p-2 cursor-pointer  text-black text-xl items-center gap-x-4 ${
                                                      Menu.gap ? "mt-9" : "mt-2"
                                                    } `}
                                                  >
                                                    <Dropdown
                                                      to={Menu.src}
                                                      onClick={() =>
                                                        setCareersOpen(
                                                          !careersOpen
                                                        )
                                                      }
                                                      className={`flex items-center w-full justify-center ${
                                                        Menu.customClass
                                                          ? Menu.customClass
                                                          : ""
                                                      }`}
                                                    >
                                                      {Menu.icon && (
                                                        <span className="mr-2">
                                                          {Menu.icon}
                                                        </span>
                                                      )}
                                                      <span className="text-2xl hover:bg-[#eccaff] p-2">
                                                        {Menu.title}
                                                      </span>
                                                    </Dropdown>
                                                    {Menu.subMenus && (
                                                      <AiFillCaretDown
                                                        style={{
                                                          verticalAlign:
                                                            "middle",
                                                          marginRight: "1px",
                                                          marginLeft: "-12px",
                                                        }}
                                                        onClick={() =>
                                                          setCareersOpen(
                                                            !careersOpen
                                                          )
                                                        }
                                                        className={`${
                                                          careersOpen &&
                                                          "rotate-180"
                                                        }`}
                                                      />
                                                    )}
                                                  </li>
                                                  {Menu.subMenus &&
                                                    careersOpen &&
                                                    open && (
                                                      <ul>
                                                        {Menu.subMenus.map(
                                                          (
                                                            subMenuItem,
                                                            idx
                                                          ) => (
                                                            <li
                                                              key={idx}
                                                              className="flex px-2 cursor-pointer text-xs text-center text-black font-semibold py-1"
                                                              style={{
                                                                listStyle:
                                                                  "none",
                                                              }}
                                                            >
                                                              <Link
                                                                to={
                                                                  subMenuItem.linkTo
                                                                }
                                                                onClick={() =>
                                                                  toggleClose()
                                                                }
                                                                className="hover:underline"
                                                                style={{
                                                                  textDecoration:
                                                                    "none",
                                                                  borderBottom:
                                                                    "1px solid transparent",
                                                                  display:
                                                                    "block",
                                                                  padding:
                                                                    "8px 10px",
                                                                  transition:
                                                                    "all 0.3s",
                                                                }}
                                                                onMouseEnter={(
                                                                  e
                                                                ) => {
                                                                  e.target.style.borderBottom =
                                                                    "3px solid black";
                                                                }}
                                                                onMouseLeave={(
                                                                  e
                                                                ) => {
                                                                  e.target.style.borderBottom =
                                                                    "1px solid transparent";
                                                                }}
                                                              >
                                                                {
                                                                  subMenuItem.title
                                                                }
                                                              </Link>
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    )}
                                                </>
                                              ) : (
                                                <>
                                                  <li
                                                    className={`flex  rounded-md p-2 cursor-pointer  text-black text-xl items-center gap-x-4 ${
                                                      Menu.gap ? "mt-9" : "mt-2"
                                                    } `}
                                                  >
                                                    <Link
                                                      to={Menu.src}
                                                      onClick={() =>
                                                        toggleClose()
                                                      }
                                                      className={`flex items-center w-full justify-center ${
                                                        Menu.customClass
                                                          ? Menu.customClass
                                                          : ""
                                                      }`}
                                                    >
                                                      {Menu.icon && (
                                                        <span className="mr-2">
                                                          {Menu.icon}
                                                        </span>
                                                      )}
                                                      <span className="text-2xl hover:bg-[#eccaff] p-2">
                                                        {Menu.title}
                                                      </span>
                                                    </Link>
                                                  </li>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <button
                    className={`p-2 ${open ? "hidden" : "block"} rounded-lg lg:hidden text-blue-900`}  
                      onClick={handleToggle}
                    >
                      <svg
                        className="h-6 w-6 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>

              <ul className="hidden md:flex flex-row items-center text-lg font-bold gap-8 -mt-3">
                <li>
                  <Link
                    to="/"
                    className="py-7 px-3 inline-block font-semibold"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    Home
                  </Link>
                  <Link
                    className="py-7 px-3 inline-block font-semibold"
                    onClick={toggleAbout}
                    onMouseEnter={showAbout}
                    onMouseLeave={hideAbout}
                  >
                    About
                    <ion-icon
                      name="chevron-down-outline"
                      style={{
                        verticalAlign: "middle",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    ></ion-icon>
                    {aboutOpen && (
                      <div className="absolute bg-white shadow-md p-2 rounded-md group-hover:block mt-2">
                        <Link
                          to="/team"
                          className="p-1 ml-2 hover:bg-[#eccaff]"
                        >
                          Our Team
                        </Link>
                      </div>
                    )}
                  </Link>

                  <Link
                    className="py-7 px-3 inline-block font-semibold"
                    onClick={toggleServices}
                    onMouseEnter={showServices}
                    onMouseLeave={hideServices}
                  >
                    Services
                    <ion-icon
                      name="chevron-down-outline"
                      style={{
                        verticalAlign: "middle",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    ></ion-icon>
                    {servicesOpen && (
                      <div className="absolute bg-white shadow-md p-2 rounded-md group-hover:block mt-2">
                        <ul className="space-y-2">
                        <Link
                            to="/staffAugmentation"
                            className="block p-1 ml-2 hover:bg-[#eccaff] "
                            onClick={() => {
                              scrollToTop();
                            }}
                          >
                            Staff Augmentation
                          </Link>
                          <Link
                            to="/mobileFriendly"
                            className="block p-1 ml-2 hover:bg-[#eccaff]"
                            onClick={() => {
                              scrollToTop();
                            }}
                          >
                            Get Your free Webapp
                          </Link>

                          <Link
                            to="/seoConfiguration"
                            className="block p-1 ml-2 hover:bg-[#eccaff] "
                            onClick={() => {
                              scrollToTop();
                            }}
                          >
                            SEO Configuration
                          </Link>
                          
                          <Link
                            to="/buildYourVision"
                            className="block p-1 ml-2 hover:bg-[#eccaff]"
                            onClick={() => {
                              scrollToTop();
                            }}
                          >
                            Employ a complete stack team
                          </Link>
                          <Link
                            to="/transformIdeas"
                            className="block p-1 ml-2 hover:bg-[#eccaff]"
                            onClick={() => {
                              scrollToTop();
                            }}
                          >
                            Free poc/mvp.
                          </Link>
                          <Link
                            to="/projectProcess"
                            className="block p-1 ml-2 hover:bg-[#eccaff]"
                            onClick={() => {
                              scrollToTop();
                            }}
                          >
                            Our project workflow.
                          </Link>
                        </ul>
                      </div>
                    )}
                  </Link>

                  <Link
                    className="py-7 px-3 inline-block font-semibold"
                    onClick={toggleCareers}
                    onMouseEnter={showCareers}
                    onMouseLeave={hideCareers}
                  >
                    Careers
                    <ion-icon
                      name="chevron-down-outline"
                      style={{
                        verticalAlign: "middle",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    ></ion-icon>
                    {careersOpen && (
                      <div className="absolute bg-white shadow-md p-2 rounded-md group-hover:block mt-2">
                        <Link
                          to="/trainingAndHiring"
                          className="block ml-2 p-1  hover:bg-[#eccaff]"
                          onClick={() => {
                            scrollToTop();
                          }}
                        >
                          Train Hire
                        </Link>
                        <Link
                          to="/directHiringMain"
                          className="block ml-2 p-1  hover:bg-[#eccaff]"
                          onClick={() => {
                            scrollToTop();
                          }}
                        >
                          Direct Hire
                        </Link>

                        <Link
                          to="/directInternMain"
                          className="block ml-2 p-1  hover:bg-[#eccaff]"
                          onClick={() => {
                            scrollToTop();
                          }}
                        >
                          Direct Intern
                        </Link>
                        <Link
                          to="/trainingForExperiencedMain"
                          className="block ml-2 p-1  hover:bg-[#eccaff]"
                          onClick={() => {
                            scrollToTop();
                          }}
                        >
                          Training for Experienced
                        </Link>
                      </div>
                    )}
                  </Link>

                  <a className="py-7 px-3 inline-block font-semibold">
                    <Link to="https://edu.yaazhtech.com/">Education </Link>{" "}
                  </a>

                  <Link
                    to="/contact"
                    className="py-7 px-3 inline-block font-semibold"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    Contact Us
                  </Link>
                </li>
                
                <LanguageSelector/>
                <div className="ml-10">
                  <Link
                    to="/login"
                    className=""
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    <button
                      className="mt-2 w-50 rounded-full px-7 pb-2.5 pt-3  text-sm text-white"
                      style={{ backgroundColor: currentColor }}
                    >
                      <span>Login</span>
                    </button>
                  </Link>
                </div>
              </ul>
              
            </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
{
  /*Mobile Responsive Menu items*/
}
const Menus = [
  {
    title: "Home",
    src: "/home",
    icon: <RiHomeWifiFill className="w-8 h-8 -ml-10" />,
    customClass: "-ml-5",
  },
  {
    title: "About",
    //src: "/about",
    icon: <FcAbout className="w-8 h-8 -ml-10 custom-black-icon" />,
    customClass: "-ml-4",
    subMenus: [
      {
        title: "Team",
        linkTo: "/team",
        cName: "sub-nav",
      },
    ],
  },

  {
    title: "Services",
    //src: "/mobileFriendly",
    icon: <GrServices className="w-7 h-8 -ml-10 custom-black-icon1 " />,
    customClass: "ml-1",
    subMenus: [
      {
        title: "Staff Augmentation",
        linkTo: "/hireCoder",
        cName: "sub-nav",
      },
      {
        title: "Get Your free Webapp",
        linkTo: "/mobileFriendly",
        cName: "sub-nav",
      },
      {
        title: "Employ a complete stack team",
        linkTo: "/buildYourVision",
        cName: "sub-nav",
      },
      {
        title: "Free poc/mvp.",
        linkTo: "/transformIdeas",
        cName: "sub-nav",
      },
      {
        title: "Our project workflow.",
        linkTo: "/projectProcess",
        cName: "sub-nav",
      },
    ],
  },

  {
    title: "Careers",
    //src: "/mobileFriendly",
    icon: <IoBriefcaseOutline className="w-7 h-8 -ml-10 " />,
    customClass: "-ml-1",

    subMenus: [
      {
        title: "Train Hire",
        linkTo: "/trainingAndHiring",
        cName: "sub-nav",
      },
      {
        title: "Direct Hire",
        linkTo: "/directHire",
        cName: "sub-nav",
      },
      {
        title: "Direct Intern",
        linkTo: "/directInternMain",
        cName: "sub-nav",
      },
      {
        title: "Training for Experienced",
        linkTo: "/trainingForExperiencedMain",
        cName: "sub-nav",
      },
      {
        title: "Education",
        linkTo: "/education",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Contact ",
    src: "/contact",
    icon: <BiSolidContact className="w-8 h-8 -ml-11" />,
    customClass: "-ml-3 mb-3",
  },
];

export default Navbar;