import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { FiCopy } from 'react-icons/fi';
import { getContent, getEndpoints } from '../../../api/Api';
import logo from '../../../assets/images/logos/logoFinal30x30.png';
import ReactPlayer from 'react-player';
import toast, { Toaster } from 'react-hot-toast';
import { Typography } from '@material-tailwind/react';

const ContentManagement = (props) => {
  const [content, setContent] = useState({});
  const [endpoints, setEndpoints] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [leftNavigationList, setLeftNavigationList] = useState([]);
  const [youTubeLink, setYouTubeLink] = useState(" ");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEndPointData();
    console.log('YouTube Link:', youTubeLink);
  }, [props.endpoint, props.category]);

  const getEndPointData = () => {
    getEndpoints()
      .then((res) => {
        if (res.data === null) {
          toast(res.message);
          //JSON.stringify("Endpoint msggg",res.message);

        } else {
          setEndpoints(res.data);

          let endpoint = '';
          if (endpoints) {
            for (let i = 0; i < endpoints.length; i++) {
              if (endpoints[i].category === props.category) {
                const firstEndpoint = endpoints[i];
                const { contentStateList } = firstEndpoint;
                setLeftNavigationList(contentStateList);
                const contentDetailsData = contentStateList[0];
                endpoint = contentDetailsData.endpoint;
              }
            }
            if (props.endpoint !== undefined && props.endpoint !== null) {
              getContent(props.endpoint, props.category)
                .then((res) => {
                  if (res.data === null) {
                    toast(res.message);
                   // JSON.stringify("Endpoint msg",res.message);
                  } else {
                    setContent(res.data);
                    setContentList(res.data.contentList);                   
                     setYouTubeLink(res.data.youTubeLink);
                  }
                  setLoading(false);
                })
                .catch((error) => {
                  if (error.status === 401) {
                    toast(message);
                  }
                  setLoading(false);
                });
            }
          }

          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          toast(message);
        } else {
          toast(error.message);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <div className="container mx-auto p-4 sm:p-8">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          <div className="md:col-span-1">
            <div className="col-md-2 p-0">
              {/* Sidebar Content */}
            </div>

            {/* Main Content */}
            <div className="container mx-auto p-4 sm:p-8">
              {/* Content Header */}
              <h1 className="text-4xl font-bold">{content.primaryHeader}</h1>
              <br></br>
              <div className="published date d-flex">
                <h4 className="text-bold" style={{ fontWeight: 500 }}>
                  Last Updated: {content.createdAt}
                </h4>
              </div>
<br></br>
           


              {/* Content Body */}
              <div>
                
                {contentList.map((item, i) => (
                  <div key={i}>
                    <br></br>
                    <h4 className="text-bold text-xl uppercase font-semibold">
                      {item.contentHeader}
                    </h4>
                    <div className="p-1">
                      {item.contentBody.split('- ').map((bodyItem, j) => (
                        <p key={j} className="text-lg">
                          {bodyItem}
                        </p>
                      ))}
                    </div>
                    <div className="p-1">
                      {item.codeSnippet != null && (
                        <div className="script-header">
                          <div className="code-snippet">
                            <div className="code-snippet-right" />
                            <div className="code-snippet-right">
                              <FiCopy
                                icon={FiCopy}
                                onClick={() => {
                                  navigator.clipboard.writeText(item.codeSnippet);
                                }}
                              />
                            </div>
                          </div>
                          <br />
                          {item.codeSnippet.split('- ').map((bodyItem, j) => (
                            <p key={j} className="text-sm">
                              {bodyItem}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                    <br />
                    {item.youTubeLink && (
    <div className="relative mb-5" style={{ paddingTop: '56.25%' }}>
     
        <div className="absolute top-0 left-0 lg:w-3/4 lg:h-full">
          <ReactPlayer
            url={`${item.youTubeLink}`}
            className="react-player"
            width="100%"
            height="100%"
            controls={true}          />
        </div>
        </div>
      )}
                    <p>
                      {item.contentImageBody && (
                        <img
                          src={`${item.contentImageBody}`}
                          className="script-header mx-auto"
                          alt="Content Image"
                        />
                      )}
                    </p>
             
                    
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: '#FF4136',
              color: '#fff',
            },
          },
          success: {
            duration: 6000,
            style: {
              background: '#008000',
              color: '#fff',
            },
          },
        }}
      />
    </>
  );
};

export default ContentManagement;
