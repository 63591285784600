import React, { useEffect, useState } from "react";
import { useContextSelector } from "use-context-selector";
import {
  BrowserRouter,
  Routes,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  ScrollRestoration,
  Outlet,
  Navigate,
} from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import ContentManagement from "./pages/dynamic/content/ContentManagement";
import SplashScreen from "./components/splashScreen/SplashScreen";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import DevSubscriptionPricing from "./pages/static/business/DevSubscriptionPricing";
import DevSubscriptionPricingSeo from "./pages/static/business/DevSubscriptionPricingSeo";
import DevSubscriptionPricingYear from "./pages/static/business/DevSubscriptionPricingYear";
import {
  Home,
  Team,
  Services,
  Project_Process,
  Tech_Stack,
  Contact,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  Training,
  Experienced,
  Education,
  Ecommerce,
} from "./pages";
import "./App.css";
import { splashScreenContent } from "./data/content/PageContent";
import Login from "./pages/dynamic/login/Login";
import { StateContext } from "./contexts/StateContext";
import {
  ACCESS_TOKEN,
  ADMIN_TOKEN,
  HR_TOKEN,
  CLIENT_TOKEN,
  FINANCIER_TOKEN,
  EMPLOYEE_TOKEN,
} from "./config/Config";
import Forgot from "./pages/dynamic/login/ForgotPassword";
import Register from "./pages/dynamic/register/Register";
import HireCoderRegister from "./pages/dynamic/register/HireCoderRegister";
import CareersRegister from "./pages/dynamic/register/CareersRegister";
import EducationRegister from "./pages/dynamic/register/EducationRegister";
import Content from "./pages/dynamic/user/publish/content/Content";
import Privacy from "./pages/static/policy/privacy";
import Terms from "./pages/static/policy/terms";
import CancellationPolicy from "./pages/static/policy/CancellationPolicy";
import About from "./pages/static/home/About";
import Onboard from "./pages/dynamic/user/assessment/Onboard";
import Request from "./pages/dynamic/user/assessment/Request";
import TrainingRegister from "./pages/dynamic/register/TrainingRegister";
import Applicants from "./pages/dynamic/hr/dashboard/applicants/Applicants";
import ClientData from "./pages/dynamic/financier/dashboard/payment/ClientData";
import Bills from "./components/Bills";
import { is } from "@babel/types";
import PersonalDataSettings from "./pages/dynamic/user/account/settings/profile/PersonalDataSettings";
import ClockTime from "./pages/dynamic/employee/dashboard/profile/timesheet/ClockTime";
import AccountDataSettings from "./pages/dynamic/user/account/settings/profile/AccountDataSettings";
import AccountSettings from "./pages/dynamic/user/account/settings/account/AccountSettings";
import LeaveManagement from "./pages/dynamic/employee/dashboard/profile/lms/LeaveManagement";
import ProfileDataSettings from "./pages/dynamic/user/account/settings/profile/ProfileDataSettings";
import BulkEmailing from "./pages/dynamic/user/email/bulkEmail/mail/BulkEmailing";
import LetterHead from "./pages/dynamic/hr/dashboard/letterHead/LetterHead";
import EmployeeData from "./pages/dynamic/hr/dashboard/employee/EmployeeData";
import PolicyHub from "./pages/dynamic/employee/dashboard/profile/policyHub/PolicyHub";
import InternData from "./pages/dynamic/hr/dashboard/intern/InternData";
import TraineeData from "./pages/dynamic/hr/dashboard/trainee/TraineeData";
import MyProfile from "./pages/dynamic/employee/dashboard/profile/myProfile/MyProfile";
import BackendData from "./pages/dynamic/client/profiles/dashboard/backend/BackendData";
import ViewClient from "./pages/dynamic/financier/dashboard/payment/ViewClient";
import ManualSubscription from "./pages/dynamic/financier/dashboard/manualSubscription/ManualSubscription";
import SecuritySettings from "./pages/dynamic/user/account/settings/security/SecuritySettings";
import OfferLetter from "./pages/dynamic/admin/dashboard/letterOfEmployment/OfferLetter";
import SalaryStatement from "./pages/dynamic/admin/dashboard/letterOfEmployment/SalaryStatement";
import ResetPassword from "./pages/dynamic/resetPassword/ResetPassword";
import MobileFriendly from "./pages/static/policy/MobileFriendly";
import HireCoder from "./pages/static/policy/HireCoder";

import BuildYourVision from "./pages/static/BuildYourVision";
import TransformIdeas from "./pages/static/policy/TransformIdeas";
import Contract from "./pages/static/policy/Contract";
import SkillsDataSettings from "./pages/dynamic/user/account/settings/profile/SkillsDataSettings";
import DocumentDataSettings from "./pages/dynamic/user/account/settings/profile/DocumentDataSettings";
import DirectIntern from "./pages/static/DirectIntern";
import DirectInternRegister from "./pages/dynamic/register/DirectInternRegister";
import ViewInvoiceDetails from "./pages/dynamic/client/dashboard/payment/ViewInvoiceDetails";

import PaymentGateway from "./pages/dynamic/payment/PaymentGateway";
import PaymentSuccessPage from "./pages/dynamic/payment/PaymentSuccessPage";
import ConfirmSubscription from "./pages/dynamic/register/ConfirmSubscription";
import ConfirmUnSubscription from "./pages/dynamic/register/ConfirmUnSubscription";
import TrainHireDoc from "./pages/static/policy/TrainHireDoc";
import EducationDoc from "./pages/static/policy/EducationDoc";
import HolidayType from "./pages/dynamic/admin/dashboard/letterOfEmployment/HolidayType";
import ExperienceLetter from "./pages/dynamic/admin/dashboard/letterOfEmployment/ExperienceLetter";
import DirectHire from "./pages/static/policy/DirectHire";
import DirectInternDoc from "./pages/static/policy/DirectInternDoc";
import TrainingInvoiceDetails from "./pages/dynamic/client/dashboard/payment/TrainingInvoiceDetails";
import Httptrace from "./pages/dynamic/admin/dashboard/httptraces/Httptrace";
import Health from "./pages/dynamic/admin/dashboard/util/Health";
import Metrics from "./pages/dynamic/admin/dashboard/util/Metrics";
import UsersData from "./pages/dynamic/admin/dashboard/users/UsersData";
import FrontendData from "./pages/dynamic/client/profiles/dashboard/frontend/FrontendData";
import FullStackData from "./pages/dynamic/client/profiles/dashboard/fullstack/FullStackData";
import Dashboard from "./pages/dynamic/admin/dashboard/index/Dashboard";
import AssessmentsGridLine from "./pages/dynamic/user/assessment/assessments/AssessmentsGridLine";
import AssessmentsGrid from "./pages/dynamic/user/assessment/assessments/AssessmentsGrid";
import UiDeveloperDoc from "./pages/static/policy/UiDeveloperDoc";
import BackendDeveloperDoc from "./pages/static/policy/BackendDeveloperDoc";
import FlexPLMData from "./pages/dynamic/client/profiles/dashboard/flexplm/FlexPLMData";
import IamData from "./pages/dynamic/client/profiles/dashboard/iam/IamData";
import CamundaData from "./pages/dynamic/client/profiles/dashboard/camunda/CamundaData";
import FullStackDeveloperDoc from "./pages/static/policy/FullStackDeveloperDoc";
import EditContent from "./pages/dynamic/user/publish/editcontent/EditContent";
import UpdateContent from "./pages/dynamic/user/publish/editcontent/UpdateContent";
import DeleteContent from "./pages/dynamic/user/publish/editcontent/DeleteContent";
import ProfileTabs from "./pages/dynamic/employee/dashboard/profile/myProfile/ProfileTabs";

import ProjectList from "./pages/dynamic/scrum/ProjectList";
import ViewEmployeeProfiles from "./pages/dynamic/hr/dashboard/ViewEmployeeProfiles";
import ProjectListForEmployee from "./pages/dynamic/scrum/ProjectListForEmployee";
import ProjectListForClient from "./pages/dynamic/scrum/ProjectListForClient";
import TrackAssessment from "./pages/dynamic/hr/dashboard/trackAssessments/TrackAssessment";
import SubscriptionTabs from "./pages/dynamic/financier/dashboard/manualSubscription/SubscriptionTabs";
import ViewTransaction from "./pages/dynamic/financier/dashboard/payment/ViewTransaction";
import { FaWhatsapp } from "react-icons/fa";

import StaffAugmentation from "./pages/static/policy/StaffAugmentation";
import StaffAugSoftwareDevelopement from "./pages/static/policy/StaffAugSoftwareDevelopement";
import StaffAugQaAndTesting from "./pages/static/policy/StaffAugQaAndTesting";

import StaffAugTalentAcquisitionSpecialist from "./pages/static/policy/StaffAugTalentAcquisitionSpecialist";
import StaffAugDatabaseEngineer from "./pages/static/policy/StaffAugDatabaseEngineer";
import TrainingAndHiring from "./pages/static/TrainingAndHiring";

import Assessments from "./pages/dynamic/user/assessment/assessments/Assessments";
import TrainingForExperienced from "./pages/static/TrainingForExperienced";
import Layout from "./Layout";
import DirectHiringMain from "./pages/static/DirectHiringMain";
import SeoConfiguration from "./pages/static/policy/SeoConfiguration";
import DirectInternMain from "./pages/static/DirectInternMain";
import TrainingForExperiencedMain from "./pages/static/TrainingForExperiencedMain";
import AssessmentAttempt from "./pages/dynamic/user/assessment/assessments/AssessmentAttempt";
import ViewAnswers from "./pages/dynamic/hr/dashboard/trackAssessments/ViewAnswers";
import ApplicantsAnswer from "./pages/dynamic/hr/dashboard/trackAssessments/ApplicantsAnswer";
import StudentAnswer from "./pages/dynamic/hr/dashboard/trackAssessments/StudentAnswer";
import Rejected from "./pages/dynamic/hr/dashboard/reject/Rejected";
import OverallTransaction from "./pages/dynamic/financier/dashboard/OverallTransaction";
import AssessmentMcq from "./pages/dynamic/user/assessment/assessments/AssessmentMcq";

import DeleteAssessment from "./pages/dynamic/user/assessment/assessments/DeleteAssessment";
import { AuthProvider } from "./contexts/AuthContext";
import Careers from "./pages/dynamic/careers/Careers";
import LeaderShip from "./pages/static/leadership/LeaderShip";

function App() {
  const setCurrentColor = useContextSelector(
    StateContext,
    (state) => state.setCurrentColor
  );
  const setCurrentMode = useContextSelector(
    StateContext,
    (state) => state.setCurrentMode
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const activeMenu = useContextSelector(
    StateContext,
    (state) => state.activeMenu
  );

  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const themeSettings = useContextSelector(
    StateContext,
    (state) => state.themeSettings
  );
  const setThemeSettings = useContextSelector(
    StateContext,
    (state) => state.setThemeSettings
  );
  const contentEndpoints = useContextSelector(
    StateContext,
    (state) => state.contentEndpoints
  );
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const role = useContextSelector(StateContext, (state) => state.role);
  const setRole = useContextSelector(StateContext, (state) => state.setRole);
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] =
    useState(true);

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  useEffect(() => {
    if (splashScreenContent.enabled) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        splashScreenContent.duration
      );
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);
  useEffect(() => {
    isAuthenticated();
  }, []);
  const isAuthenticated = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (
      token !== "undefined" &&
      token !== undefined &&
      token !== null &&
      token !== "null" &&
      token !== ""
    ) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      token = JSON.parse(window.atob(base64));
      if (token.exp <= Math.floor(Date.now() / 1000)) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(ADMIN_TOKEN);
        localStorage.removeItem(HR_TOKEN);
        localStorage.removeItem(EMPLOYEE_TOKEN);
        localStorage.removeItem(CLIENT_TOKEN);
        localStorage.removeItem(FINANCIER_TOKEN);

        setIsLoggedIn(false);
        return false;
      }

      if (token.rol.length >= 1) {
        setRole(token.rol[0]);
        if (token.rol[0] && token.rol[0] === "ADMIN") {
          localStorage.setItem(ADMIN_TOKEN, token.rol[0]);
        } else if (token.rol[0] && token.rol[0] === "HR") {
          localStorage.setItem(HR_TOKEN, token.rol[0]);
        } else if (token.rol[0] && token.rol[0] === "EMPLOYEE") {
          localStorage.setItem(EMPLOYEE_TOKEN, token.rol[0]);
        } else if (token.rol[0] && token.rol[0] === "CLIENT") {
          localStorage.setItem(CLIENT_TOKEN, token.rol[0]);
        } else if (token.rol[0] && token.rol[0] === "FINANCIER") {
          localStorage.setItem(FINANCIER_TOKEN, token.rol[0]);
        }
      }
      setIsLoggedIn(true);
      return true;
    } else {
      return false;
    }
  };
  if (isShowingSplashAnimation && splashScreenContent.enabled) {
    return <SplashScreen />;
  }

  const cmsRoutes = [];
  let routes = [];
  let adminRoutes = [];
  let hrRoutes = [];
  let employeeRoutes = [];
  let clientRoutes = [];
  let financierRoutes = [];

  for (let i = 0; i < contentEndpoints.length; i++) {
    contentEndpoints[i].contentStateList.map((endpointData, endpointIndex) =>
      cmsRoutes.push(
        <Route
          key={endpointIndex + i + 25}
          exact
          path={`/${contentEndpoints[i].category}-${endpointData.endpoint}`}
          Component={(props) => (
            <ContentManagement
              {...props}
              category={contentEndpoints[i].category}
              endpoint={endpointData.endpoint}
            />
          )}
        />
      )
    );
  }

  const handleClick = () => {
    const currentUrl = window.location.href;

    // Check if the current page is the career page
    if (
      currentUrl.includes("/trainingAndHiring") ||
      currentUrl.includes("/directHire") ||
      currentUrl.includes("/education") ||
      currentUrl.includes("/directIntern")
    ) {
      // Redirect to a different WhatsApp number for career pages
      window.location.href = "https://wa.me/918072488209";
    } else {
      // Redirect to the default WhatsApp number for other pages
      window.location.href = "https://wa.me/916382289060";
    }
  };

  // up scroll
  // 401 for unathorized 404 for not available endpoint
  const renderRoutes = (isLoggedIn) => {
    if (isLoggedIn) {
      return (
        <>
          <Route path="/myProfile" element={<ProfileTabs />} />,
          <Route path="/content" element={<Content />} />,
          <Route path="/editcontent" element={<EditContent />} />,
          <Route path="/updatecontent" element={<UpdateContent />} />,
          <Route path="/deletecontent" element={<DeleteContent />} />,
          <Route path="/request" element={<Request />} />,
          <Route path="/personalData" element={<PersonalDataSettings />} />,
          <Route path="/profileData" element={<ProfileDataSettings />} />,
          <Route path="/timesheet" element={<ClockTime />} />,
          <Route path="/accountData" element={<AccountDataSettings />} />,
          <Route path="/applyLeave" element={<LeaveManagement />} />,
          <Route path="/changePassword" element={<AccountSettings />} />,
          <Route path="/2fa" element={<SecuritySettings />} />,
          <Route path="/skills" element={<SkillsDataSettings />} />,
          <Route path="/uploadProfile" element={<DocumentDataSettings />} />,
          <Route path="/publishContent" element={<Content />} />,
          <Route
            path="/trainingInvoiceDetails"
            element={<TrainingInvoiceDetails />}
          />
          ,
          <Route path="/paymentGateway" element={<PaymentGateway />} />,
          <Route path="/stack" element={<Assessments />} />,
          <Route path="/assmentGridDoc" element={<AssessmentsGrid />} />,
          <Route path="/assessmentMcqs" element={<AssessmentMcq />} />,
          <Route path="/onboard" element={<Onboard />} />,
          <Route path="/policyHub" element={<PolicyHub />} />,
          <Route path="/" element={<Home />} />,
          <Route path="/home" element={<Home />} />,
          <Route path="/onlineAssessment" element={<AssessmentAttempt />} />,
        </>
      );
    } else {
      return (
        <>
          <Route
            path="/reset-password/:token/:id"
            element={<ResetPassword />}
          />
          ,
          <Route
            path="/subscribe/:token/:id"
            element={<ConfirmSubscription />}
          />
          ,
          <Route
            path="/unsubscribe/:token/:id"
            element={<ConfirmUnSubscription />}
          />
          ,
          <Route path="/" element={<Home />} />,
          <Route
            path="/paymentSuccessPage/:token/:id"
            element={<PaymentSuccessPage />}
          />,
          <Route path="/home" element={<Home />} />,
          <Route path="/services" element={<Services />} />,
          <Route path="/trainHire" element={<Training />} />,
          <Route path="/trainingAndHiring" element={<TrainingAndHiring />} />,
          <Route path="/directHire" element={<Experienced />} />,
          <Route path="/directHiringMain" element={<DirectHiringMain />} />
          <Route path="/education" element={<Education />} />,
          <Route path="/directIntern" element={<DirectIntern />} />,
          <Route path="/hireCoder" element={<HireCoder />} />,
          <Route path="/staffAugmentation" element={<StaffAugmentation />} />,
          <Route
            path="/staffAugSoftwareDevelopement"
            element={<StaffAugSoftwareDevelopement />}
          />
          ,
          <Route
            path="/staffAugQaAndTesting"
            element={<StaffAugQaAndTesting />}
          />
          ,
          <Route
            path="/staffAugTalentAcquisitionSpecialist"
            element={<StaffAugTalentAcquisitionSpecialist />}
          />
          ,
          <Route
            path="/staffAugDatabaseEngineer"
            element={<StaffAugDatabaseEngineer />}
          />
          ,
          <Route path="/trainHireDoc" element={<TrainHireDoc />} />,
          <Route path="/educationDoc" element={<EducationDoc />} />,
          <Route path="/educationDoc" element={<EducationDoc />} />,
          <Route path="/directHireDoc" element={<DirectHire />} />,
          <Route path="/assessments" element={<Assessments />} />,
          <Route path="/assessmentMcqs" element={<AssessmentMcq />} />,
          <Route path="/assessmentLine" element={<AssessmentsGridLine />} />,
          <Route path="/directInternDoc" element={<DirectInternDoc />} />,
          <Route path="/directInternDoc" element={<DirectInternDoc />} />,
          <Route path="/uiDeveloperDoc" element={<UiDeveloperDoc />} />,
          <Route
            path="/fullStackDeveloperDoc"
            element={<FullStackDeveloperDoc />}
          />
          ,
          <Route
            path="/backendDeveloperDoc"
            element={<BackendDeveloperDoc />}
          />
          ,
          <Route
            path="/directInternRegister"
            element={<DirectInternRegister />}
          />
          ,
          <Route
            path="/devSubscriptionPricing"
            element={<DevSubscriptionPricing />}
          />
          ,
          <Route
            path="/devSubscriptionPricingYear"
            element={<DevSubscriptionPricingYear />}
          />
          ,
          <Route path="/onlineAssessment" element={<AssessmentAttempt />} />,
          <Route path="/projectProcess" element={<Project_Process />} />,
          <Route path="/login" element={<Login isLoggedIn={isLoggedIn} />} />,
          <Route path="/team" element={<Team />} />,
          <Route path="/contact" element={<Contact />} />,
          <Route path="/privacy" element={<Privacy />} />,
          <Route path="/terms" element={<Terms />} />,
          <Route path="/policyHub" element={<PolicyHub />} />,
          <Route path="/cancellationPolicy" element={<CancellationPolicy />} />,
          <Route path="/about" element={<About />} />,
          <Route path="/ecommerce" element={<Ecommerce />} />,
          <Route path="/forgot" element={<Forgot />} />,
          <Route path="/register" element={<Register />} />,
          <Route path="/hireCoderRegister" element={<HireCoderRegister />} />,
          <Route path="/careersRegister" element={<CareersRegister />} />,
          <Route path="/trainingRegister" element={<TrainingRegister />} />,
          <Route path="/educationRegister" element={<EducationRegister />} />,
          <Route path="/resetPassword" element={<ResetPassword />} />,
          <Route path="/mobileFriendly" element={<MobileFriendly />} />
          <Route path="/seoConfiguration" element={<SeoConfiguration />} />
          <Route path="/buildYourVision" element={<BuildYourVision />} />,
          <Route path="/transformIdeas" element={<TransformIdeas />} />,
          <Route path="/contract" element={<Contract />} />,
          <Route path="/editor" element={<Editor />} />,
          <Route path="/calendar" element={<Calendar />} />,
          <Route path="/color-picker" element={<ColorPicker />} />,
          <Route path="/line" element={<Line />} />,
          <Route path="/area" element={<Area />} />,
          <Route path="/bar" element={<Bar />} />,
          <Route path="/pie" element={<Pie />} />,
          <Route path="/financial" element={<Financial />} />,
          <Route path="/color-mapping" element={<ColorMapping />} />,
          <Route path="/pyramid" element={<Pyramid />} />,
          <Route path="/stacked" element={<Stacked />} />,
          <Route path="/stack" element={<Assessments />} />,
          <Route path="/assmentGridDoc" element={<AssessmentsGrid />} />,
          <Route path="/ecommerce" element={<Ecommerce />} />,
          <Route path="/viewAnswers" element={<ViewAnswers />} />,
          <Route path="/validateAnswers" element={<ApplicantsAnswer />} />,
          <Route path="/studentAnswer" element={<StudentAnswer />} />,
          <Route path="/paymentGateway" element={<PaymentGateway />} />,
          <Route path="/paymentGateway" element={<PaymentGateway />} />,
        </>
      );
    }
  };
  const tokenRoutes = (role) => {
    if (role === "ADMIN") {
      return (
        <>
          <Route path="/" element={<Home />} />,
          <Route path="/home" element={<Home />} />,
          <Route path="/letterOfEmployment" element={<OfferLetter />} />
          <Route path="/Employment" element={<ExperienceLetter />} />,
          <Route path="/users" element={<UsersData />} />,
          <Route path="/dashboard" element={<Dashboard />} />,
          <Route path="/apiHttptraces" element={<Httptrace />} />,
          <Route path="/health" element={<Health />} />,
          <Route path="/metrics" element={<Metrics />} />,
          <Route path="/employees" element={<EmployeeData />} />,
          <Route path="/trainees" element={<TraineeData />} />,
          <Route path="/reject" element={<Rejected />} />,
          <Route path="/interns" element={<InternData />} />,
          <Route path="/viewProfileData" element={<ViewEmployeeProfiles />} />,
        </>
      );
    }
    if (role === "HR") {
      return (
        <>
          <Route path="/home" element={<Home />} />,
          <Route path="/" element={<Home />} />,
          <Route path="/kanban" element={<ProjectList />} />,
          <Route path="/viewProfileData" element={<ViewEmployeeProfiles />} />,
          <Route path="/letterOfEmployment" element={<OfferLetter />} />,
          <Route path="/employees" element={<EmployeeData />} />,
          <Route path="/trainees" element={<TraineeData />} />,
          <Route path="/reject" element={<Rejected />} />,
          <Route path="/interns" element={<InternData />} />,
          <Route path="/customers" element={<Customers />} />,
          <Route path="/applicants" element={<Applicants />} />,
          <Route path="/bulkMailing" element={<BulkEmailing />} />,
          <Route path="/trackAssessment" element={<TrackAssessment />} />,
          <Route path="/letterHead" element={<LetterHead />} />,
          <Route path="/viewAnswers" element={<ViewAnswers />} />,
          <Route path="/validateAnswers" element={<ApplicantsAnswer />} />,
          <Route path="/studentAnswer" element={<StudentAnswer />} />,
          <Route path="/onboard" element={<Onboard />} />,
          <Route path="/deleteAssessment" element={<DeleteAssessment />} />,
        </>
      );
    }
    if (role === "EMPLOYEE") {
      return (
        <>
          <Route path="/home" element={<Home />} />,
          <Route path="/" element={<Home />} />,
          <Route path="/onlineAssessment" element={<AssessmentAttempt />} />,
          <Route path="/onboard" element={<Onboard />} />,
          <Route
            path="/kanbanForEmployee"
            element={<ProjectListForEmployee />}
          />
          ,
        </>
      );
    }

    if (role === "CLIENT") {
      return (
        <>
          <Route path="/home" element={<Home />} />,
          <Route path="/" element={<Home />} />,
          <Route path="/kanbanForClient" element={<ProjectListForClient />} />,
          <Route path="/backEnd" element={<BackendData />} />,
          <Route path="/frontEnd" element={<FrontendData />} />,
          <Route path="/fullStack" element={<FullStackData />} />,
          <Route path="/flexplm" element={<FlexPLMData />} />,
          <Route path="/iam" element={<IamData />} />,
          <Route path="/camunda" element={<CamundaData />} />,
          <Route path="/invoiceAndPayments" element={<ViewInvoiceDetails />} />,
          <Route path="/paymentGateway" element={<PaymentGateway />} />,
          <Route
            exact
            path="/paymentSuccessPage"
            element={<PaymentSuccessPage />}
          />
          ,
        </>
      );
    }
    if (role === "FINANCIER") {
      return (
        <>
          <Route path="/home" element={<Home />} />,
          <Route path="/" element={<Home />} />,
          <Route path="/overallSubscription" element={<SubscriptionTabs />} />,
          {/* Overalltransaction */}
          <Route path="/overallTransaction" element={<OverallTransaction />} />,
          <Route path="/clients" element={<ClientData />} />,
          <Route path="/viewClient" element={<ViewClient />} />,
          <Route path="/viewTransaction" element={<ViewTransaction />} />,
          <Route path="/addEditInvoice" element={<ManualSubscription />} />,
          <Route path="/paymentGateway" element={<PaymentGateway />} />,
          <Route
            path="/paymentSuccessPage/:token/:id"
            element={<PaymentSuccessPage />}
          />,
        </>
      );
    }
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route path="/reset-password/:token/:id" element={<ResetPassword />} />
        <Route path="/subscribe/:token/:id" element={<ConfirmSubscription />} />
        <Route
          path="/paymentSuccessPage/:token/:id"
          element={<PaymentSuccessPage />}
        />
        <Route
          path="/unsubscribe/:token/:id"
          element={<ConfirmUnSubscription />}
        />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/trainHire" element={<Training />} />
        <Route path="/trainingAndHiring" element={<TrainingAndHiring />} />
        
        <Route path="/directHire" element={<Experienced />} />
        <Route path="/education" element={<Education />} />
        <Route path="/directInternMain" element={<DirectInternMain />} />
        <Route path="/directIntern" element={<DirectIntern />} />
        <Route path="/hireCoder" element={<HireCoder />} />

        <Route path="/staffAugmentation" element={<StaffAugmentation />} />

        <Route
          path="/staffAugSoftwareDevelopement"
          element={<StaffAugSoftwareDevelopement />}
        />
        <Route
          path="/staffAugQaAndTesting"
          element={<StaffAugQaAndTesting />}
        />
        <Route
          path="/staffAugTalentAcquisitionSpecialist"
          element={<StaffAugTalentAcquisitionSpecialist />}
        />
        <Route
          path="/staffAugDatabaseEngineer"
          element={<StaffAugDatabaseEngineer />}
        />
        <Route path="/trainHireDoc" element={<TrainHireDoc />} />
        <Route path="/educationDoc" element={<EducationDoc />} />
        <Route path="/educationDoc" element={<EducationDoc />} />
        <Route path="/directHireDoc" element={<DirectHire />} />
        <Route path="/assessments" element={<Assessments />} />
        <Route path="/assessmentLine" element={<AssessmentsGridLine />} />
        <Route path="/directInternDoc" element={<DirectInternDoc />} />
        <Route path="/directInternDoc" element={<DirectInternDoc />} />
        <Route path="/uiDeveloperDoc" element={<UiDeveloperDoc />} />
        <Route
          path="/fullStackDeveloperDoc"
          element={<FullStackDeveloperDoc />}
        />

        <Route path="/backendDeveloperDoc" element={<BackendDeveloperDoc />} />
        <Route
          path="/directInternRegister"
          element={<DirectInternRegister />}
        />

        <Route
          path="/devSubscriptionPricing"
          element={<DevSubscriptionPricing />}
        />
        <Route
          path="/devSubscriptionPricingSeo"
          element={<DevSubscriptionPricingSeo />}
        />
        <Route
          path="/devSubscriptionPricingYear"
          element={<DevSubscriptionPricingYear />}
        />
        <Route path="/projectProcess" element={<Project_Process />} />
        <Route path="/login" element={<Login isLoggedIn={isLoggedIn} />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/policyHub" element={<PolicyHub />} />
        <Route path="/cancellationPolicy" element={<CancellationPolicy />} />
        <Route path="/about" element={<About />} />
        <Route path="/leadership" element={<LeaderShip />} />
        {/* Dynamic Learning content  */}
        {cmsRoutes}
        <Route path="/ecommerce" element={<Ecommerce />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/register" element={<Register />} />
        <Route path="/hireCoderRegister" element={<HireCoderRegister />} />
        <Route path="/careersRegister" element={<CareersRegister />} />
        <Route path="/trainingRegister" element={<TrainingRegister />} />
        <Route path="/educationRegister" element={<EducationRegister />} />
        <Route
          path="/trainingExperienced"
          element={<TrainingForExperienced />}
        />
        <Route
          path="/trainingForExperiencedMain"
          element={<TrainingForExperiencedMain />}
        />

        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/mobileFriendly" element={<MobileFriendly />} />
        <Route path="/buildYourVision" element={<BuildYourVision />} />
        <Route path="/transformIdeas" element={<TransformIdeas />} />
        <Route path="/contract" element={<Contract />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/color-picker" element={<ColorPicker />} />
        {/* charts  */}
        <Route path="/line" element={<Line />} />
        <Route path="/area" element={<Area />} />
        <Route path="/bar" element={<Bar />} />
        <Route path="/pie" element={<Pie />} />
        <Route path="/financial" element={<Financial />} />
        <Route path="/color-mapping" element={<ColorMapping />} />
        <Route path="/pyramid" element={<Pyramid />} />
        <Route path="/stacked" element={<Stacked />} />
        <Route path="/stack" element={<Assessments />} />
        <Route path="/assmentGridDoc" element={<AssessmentsGrid />} />
        <Route path="/ecommerce" element={<Ecommerce />} />
        {renderRoutes(isLoggedIn)}
        {tokenRoutes(role)}
      </Route>
    )
  );

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </div>
  );
}

export default App;
