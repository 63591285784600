import React, { useState } from 'react';
import { kannaiyan, kanakarsu } from '../../../assets/images';

const BoardOfDirectors = () => {
  const [selectedDirector, setSelectedDirector] = useState(null);
  const directors = [
    {
      name: 'Kannaiyan',
      position: 'Executive Chairman',
      imageUrl: kannaiyan,
      linkedin: 'http://www.linkedin.com/in/kannaiyan-s',
      about: 'Kanniyan is an experienced leader with a focus on innovation and strategy.',
    },
    {
      name: 'Kanakarasu Manickam',
      position: 'Independent Director',
      imageUrl: kanakarsu,
      linkedin: 'https://www.linkedin.com/in/kanakarasu-manickam-6089b025/',
      about: 'Kanakarasu brings decades of experience in financial management and governance.',
    },
    // Add more directors
  ];

  const closeModal = () => setSelectedDirector(null);

  return (
    <div className="py-16 bg-white">
      <h2 className="text-3xl text-center font-bold mb-12">Board of Directors</h2>
      
      {/* Responsive director cards */}
      <div className="flex flex-wrap justify-center gap-8">
        {directors.map((director, index) => (
          <div
            key={index}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 bg-gray-50 shadow-md rounded-lg overflow-hidden transition transform hover:scale-105"
          >
            <img className="w-full h-64 object-cover" src={director.imageUrl} alt={director.name} />
            <div className="p-4">
              <h3 className="text-xl font-bold">{director.name}</h3>
              <p className="text-gray-600">{director.position}</p>
              <div className="mt-4">
                <button
                  onClick={() => setSelectedDirector(director)}
                  className="flex items-center text-primary-500 hover:text-primary-700"
                >
                  Read more
                  <span className="ml-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal Popup */}
      {selectedDirector && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-3xl mx-auto w-full md:w-auto flex flex-col md:flex-row relative">
            {/* Close Icon */}
            <button
              className="absolute top-3 right-3 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
            {/* Left Side: Image */}
            <div className="w-full md:w-1/3">
              <img className="w-full h-64 object-cover rounded" src={selectedDirector.imageUrl} alt={selectedDirector.name} />
            </div>
            {/* Right Side: Content */}
            <div className="w-full md:w-2/3 md:pl-6 mt-4 md:mt-0">
              <h3 className="text-2xl font-bold mb-2">{selectedDirector.name}</h3>
              <p className="text-gray-600 mb-4">{selectedDirector.position}</p>
              <p className="text-gray-700 mb-4">{selectedDirector.about}</p>
              <a
                href={selectedDirector.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                Connect on LinkedIn
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoardOfDirectors;
