import React from 'react';
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";
import { DirectHireMainEn } from '../../data/content/En/DirectHireMainEn';
import { DirectHireMainDe } from '../../data/content/De/DirectHireMainDe';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const DirectHiringMain = () => {
    const currentColor = useContextSelector(StateContext, state => state.currentColor);
    const language = useContextSelector(StateContext, state => state.language);

    const content = language === 'EN' ? DirectHireMainEn : DirectHireMainDe;

    return (
        <div className="container mx-auto p-4">
            <Helmet>
                <title>YaazhTech Direct Hire: Streamlined Path to Your Dream Software Dev Job (India)</title>
                <meta name="description" content="Learn about YaazhTech's Direct Hire process for UI/FS Developers in India. Discover the application steps, interview rounds, and what to expect. Apply with confidence! (₹500 application fee applies)" />
            </Helmet>

            <div className="flex justify-center items-center min-h-screen">
                <div className="max-w-xl w-full bg-white rounded-lg shadow-lg p-6 transform transition duration-500 hover:scale-105">
                    <h2 className="text-3xl font-bold uppercase mb-4" style={{ color: currentColor }}>
                        {content.title}
                    </h2>
                    <p className="text-lg mb-4">
                        {content.explain}
                    </p>
                    <p className="text-lg mb-4 font-bold">
                        {content.lookingTo}
                    </p>
                    <ul className="list-disc list-inside mb-4">
                        <li className="text-lg"><strong>Hire a coder:</strong> {content.l1}</li>
                        <li className="text-lg"><strong>Direct hire:</strong> {content.l2}</li>
                        <li className="text-lg"><strong>Find:</strong> {content.l3}</li>
                    </ul>
                    <p className="text-lg mb-4">
                        {content.noFurthur}
                    </p>
                    <div className="flex justify-center">
                        <Link to="/directHire">
                            <button
                                className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white font-bold"
                                style={{ backgroundColor: currentColor }}>
                                {content.applyButton}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DirectHiringMain;
