import React, { useState } from "react";
import { Link } from "react-router-dom";

import NavLinks from "./NavLinks";
import LanguageSelector from "./LanguageSelector";

const Navigation = () => {
    const [open, setOpen] = useState(false);
    const handleActionClick = () => {
      setOpen(false);
    }

  
  return (
    <nav className="p-1">
    <div className="flex items-center font-medium">
      <div className="z-50 p-5 md:w-auto w-full flex">

        <div className="flex">
        {/*<img src={Logo} alt="logo" className="md:cursor-pointer h-9" />*/}
        {/* <h1 className="text-3xl md:text-white ml-2">Yaazhtech</h1> */}
        </div>
        <div className={`${open ? "hidden" : "md:block"} md:hidden `}>
        <LanguageSelector/>
        </div>
       
        <div className="text-3xl md:hidden flex items-center" onClick={() => setOpen(!open)}>
          <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
        </div>
        
      </div>
      <ul className="md:flex hidden  items-center   gap-8">

      <NavLinks actionClick={handleActionClick} />
        <li>
          <Link to="/" className="py-7 px-3 inline-block text-lg text-dark hover:text-pink-600">
            Demo
          </Link>
          </li>
          <li>
          <Link to="/" className="py-7 px-3 inline-block text-lg text-dark hover:text-pink-600">
            Pricing
          </Link>
          </li>
          <li>
          <Link to="/login" className="py-7 px-3 inline-block text-lg text-dark hover:text-pink-600">
            Login
          </Link>
        </li>
        <LanguageSelector/>
      </ul>
      
      <div className="md:block hidden">
        {/* <Button /> */}
      </div>
      {/* Mobile nav */}
      <ul
        className={`
      md:hidde bg-[#f5f5f5] fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
      duration-500 ${open ? "left-0" : "left-[-100%]"}
      `}
      >

        <NavLinks actionClick={handleActionClick} />
          <li>
          <Link to="/" className="py-7 px-3 inline-block" onClick={handleActionClick}>
            Demo
          </Link>
          </li>
          <li>

          <Link to="/" className="py-7 px-3 inline-block" onClick={handleActionClick}>

            Pricing
          </Link>
          </li>
          <li>
          <Link to="/login" className="py-7 px-3 inline-block" onClick={handleActionClick}>
            Login
          </Link>
        </li>
        
      </ul>
    </div>
  </nav>
  )
}
export default Navigation;

