import React, { useState } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { leaveUpdateRequest } from "../../../../../api/HrApi";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const ApplyLeave = ({ name, email }) => {
  const navigate = useNavigate();
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const [leaveRequest, setLeaveRequest] = useState({
    email: "",
    leaveType: "",
    leaveFrom: "",
    leaveTo: "",
    noOfDays: "",
    leaveSummary: "",
    flowFrom: "hrPage",
  });
  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  const updateLeaveType = (event) => {
    setLeaveRequest({ ...leaveRequest, leaveType: event.target.value });
  };
  const updateLeaveFrom = (event) => {
    setLeaveRequest({ ...leaveRequest, leaveFrom: event.target.value });
  };
  const updateLeaveTo = (event) => {
    setLeaveRequest({ ...leaveRequest, leaveTo: event.target.value });
  };
  const updateNoOfLeaveDay = (event) => {
    setLeaveRequest({ ...leaveRequest, noOfDays: event.target.value });
  };
  const updateLeaveSummary = (event) => {
    setLeaveRequest({ ...leaveRequest, leaveSummary: event.target.value });
  };
  const leaveUpdate = (event) => {
    event.preventDefault();
    let req = leaveRequest;
    req.email = email;
    setLeaveRequest(req);
    setShowLoading(true);
    leaveUpdateRequest(leaveRequest)
      .then((res) => {
        toast.success(res.data.message + leaveRequest.email)
        setShowLoading(false);
        setShowModal(false);
        navigate('/trainees');
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error(error.message);
        } else {
          toast.error(error.message);
        }
        setShowModal(false)
        setShowLoading(false)
       
       
      });
  };

  return (
    <div>
    
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-2xl">
              <div className="border-0 px-3 mt-16 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto bg-white border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-white text-red  h-6 w-6 text-2xl ">
                      <IoClose />
                    </span>
                  </button>
                </div>
                <h2 className="card-title text-center text-lg font-semibold">
                  Apply Leave
                </h2>
                <div className="relative p-0 px-3 flex-auto">
                  <h3 className=" font-bold text-md">
                    {" " + name + " { " + email + " }"}
                  </h3>
                  <form onSubmit={leaveUpdate}>
                    <div>
                      <div className="form-group">
                        <label htmlFor="category">Leave Type</label>
                        <select
                          className="block w-full mt-2
                     bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight border-2 focus:bg-white focus:border-gray-500"
                          //focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"

                          // className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          name="option"
                          value={leaveRequest.leaveType}
                          onChange={(event) => updateLeaveType(event)}
                        >
                          <option value="Leave Type">Leave Type</option>
                          <option value="sickLeave">Sick Leave</option>
                          <option value="vacationLeave">vacation Leave</option>
                          <option value="lopLeave">Lop Leave</option>
                          <option value="paternityLeave">
                            Paternity Leave
                          </option>
                          <option value="maternityLeave">
                            Maternity Leave
                          </option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputDate" className="font-bold">
                          leave From
                        </label>
                        <br />
                        <input
                          type="date"
                          className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="inputLeaveFrom"
                          name="inputLeaveFrom"
                          placeholder="Leave From"
                          required
                          autoComplete="on"
                          onChange={updateLeaveFrom}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputLeaveTo" className="font-bold">
                          Leave To
                        </label>
                        <br />
                        <input
                          type="date"
                          className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="inputLeaveTo"
                          name="inputLeaveTo"
                          placeholder="Leave To"
                          required
                          autoComplete="on"
                          onChange={updateLeaveTo}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputNoOfLeave" className="font-bold">
                          No of Days
                        </label>
                        <br />
                        <input
                          type="number"
                          className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="inputNoOfDays"
                          name="inputNoOfDays"
                          placeholder="No of leaves"
                          required
                          autoComplete="on"
                          onChange={updateNoOfLeaveDay}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputLeave" className="font-bold">
                          Leave summary
                        </label>
                        <br />
                        <input
                          type="text"
                          className="form-control border mt-2 appearance-none block w-full bg-gray-200 text-gray-700 border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="inputLeave"
                          name="inputLeaveTo"
                          placeholder="Leave reason"
                          required
                          autoComplete="on"
                          onChange={updateLeaveSummary}
                        />
                      </div>
                      <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          style={{ backgroundColor: currentColor }}
                          className="text-white font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default ApplyLeave;
