import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "./Mylinks";
import TrendingVideos from "./TrendingVideos";

const NavLinks = ({actionClick}) => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [open, setOpen] = useState(false);
  return (
    <>
    <div className="md:flex">
      {links.map((link) => (
        <div>
          <div className="px-3 text-left md:cursor-pointer group">
            <h1
              className="py-7 flex justify-between md:text-dark text-lg items-center md:pr-0 pr-5 group"
              onClick={() => {
                heading !== link.name ? setHeading(link.name) : setHeading("");
                setSubHeading("");
                setOpen(!open);
              }}
            >
              {link.name}
              <span className="text-xl md:hidden inline">
                <ion-icon
                  name={`${
                    heading === link.name ? "chevron-up" : "chevron-down"
                  }`}
                ></ion-icon>
              </span>
              <span className="text-xl md:mt-1 md:ml-2  md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                <ion-icon name="chevron-down"></ion-icon>
              </span>
            </h1>
            {link.submenu && open && (
              <div>
                <div className="absolute top-25 right-0 px-10 py-5 w-full h-[545px]  bg-white hidden group-hover:md:block hover:md:block">
                 
                  <div className="flex gap-10">
                    {link.sublinks.map((mysublinks) => (
                      <div className="flex relative">
                      <div className="w-[600px] flex flex-col gap-10 after:border-r-4 after:border-gray-500 after:absolute after:w-1 after:h-[104%] after:bottom-0 after:right-0 after:content-['']">
                        <h1 className="text-2xl font-semibold">
                          {mysublinks.Head} <span className="float-right mr-44"> {mysublinks?.Head1}</span>
                        </h1>
                        <ul className="list-none flex gap-6 items-start pl-0" style={{flexFlow:"wrap"}}>
                        {mysublinks.sublink.map((slink) => (
                          <li className="h-auto">
                           {
                            slink.image && (
                              <img src={slink.image} alt="image" className="w-[284px] mb-4 pr-6 h-[163px] object-cover rounded-md" />
                            )
                           }
                           {
                            slink.video && (
                              <img src={slink.video} alt="image" className="w-[284px] mb-4 pr-6 h-[163px] object-cover rounded-md" />
                         
                            )
                           }

                            <Link
                              to={slink.link}
                              target={slink?.blank} rel={slink?.rel}
                              className="hover:text-primary font-bold no-underline "
                              onClick={()=>{setOpen(false)}}
                            >
                              <div className="h-full flex-col flex justify-between">
                                <div className="w-[284px] flex gap-1 flex-col">
                                  <span className="font-semibold text-lg text-[rgb(29, 32, 38)] mb-2"> {slink.name}</span>
                                  <p className="text-md text-[rgb(93, 100, 118)] font-normal mb-2 ">{slink?.content}</p>
                                </div>

                              </div>
                             
                            </Link>
                           
                          </li>
                        ))}
                        </ul>
                       
                      </div>
                      </div>
                      
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Mobile menus */}
          <div
            className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}
          >
            {/* sublinks */}
            {link.sublinks.map((slinks) => (
              <div>
                <div>
                  <h1
                    onClick={() =>
                      subHeading !== slinks.Head
                        ? setSubHeading(slinks.Head)
                        : setSubHeading("")
                        
                    }
                    className="py-4 pl-7 bg-[#f5f5f5] font-semibold md:pr-0  flex justify-between items-center  pr-5"
                  >
                    {slinks.Head}

                    <span className="text-xl md:mt-1 md:ml-2 inline">
                      <ion-icon
                        name={`${
                          subHeading === slinks.Head
                            ? "chevron-up"
                            : "chevron-down"
                        }`}
                      ></ion-icon>
                    </span>
                  </h1>
                  <div
                    className={`${
                      subHeading === slinks.Head ? "md:hidden" : "hidden"
                    }`}
                  >
                    {slinks.sublink.map((slink) => (
                      <li className="py-3 pl-14">
                        <Link to={slink.link}  target={slink?.blank} rel={slink?.rel} onClick={actionClick}>{slink.name}</Link>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      </div>
    </>
  );
};

export default NavLinks;
