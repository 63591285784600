import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { Link, Navigate } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import toast, { Toaster } from "react-hot-toast";
import { documentGeneration } from "../../../../../api/HrApi";
import Holiday from "./Holiday";

const LetterHead = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const [letterRequest, setLetterRequest] = useState({
    header: "",
    subHeader: "",
    documentBody: "",
  });

  const [type, setType] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    document.title = "LetterHead";
  }, []);
  const updateHeader = (event) => {
    setLetterRequest({ ...letterRequest, header: event.target.value });
  };
  const updateSubHeader = (event) => {
    setLetterRequest({ ...letterRequest, subHeader: event.target.value });
  };
  const updateDocumentBody = (event) => {
    setLetterRequest({ ...letterRequest, documentBody: event.target.value });
  };
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };

  const docGeneration = (event) => {
    event.preventDefault();
    //alert("data" + JSON.stringify(letterRequest));
    setShowLoading(true);
    documentGeneration(letterRequest)
      .then((res) => {
        let binaryString = window.atob(res.data);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });

        let link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = letterRequest.header + ".pdf";

        link.click(); /*

        alert("res"+res.data);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        alert("url"+url);
        const link = document.createElement('a');
        alert("link"+link);
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
*/
        toast.success("Letter generated successfully !");
        //toast.success("success")

        setShowLoading(false);
        setLetterRequest("");
        //  this.props.history.push("/hr/dashboard/letterHead");
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error("Your username or email does not exist !");
          //toast.error("error")
        } else {
          toast.error(error.message);
          //toast.error("error")
        }
        setShowLoading(false);
      });
  };

  return (
    <div>
      <div className="container grid md:grid-cols-2 p-4">
        <div className="p-6  rounded bg-[#E6F6FA] m-3">
          {showLoading ? (
            <div className="align-content-center text-center">
              <h5>LetterHead up</h5>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                refresh
              </i>
            </div>
          ) : (
            <form onSubmit={docGeneration}>
              <div className="card card-signin my-5 -mt-10">
                <div className="card-body ml-5 mr-5 mt-5">
                  <h1 className="text-xl uppercase flex text-center justify-center font-semibold mb-2">
                    Generate Document
                  </h1>
                </div>

                <p className="text-base flex justify-center mb-2">
                  Letter Generation
                </p>

                <br />

                <div>
                  <h2 className="font-semibold">Header </h2>
                  <input
                    type="text"
                    className="form-control w-full text-base py-2 my-4 bg-transparent
            text-black border-b border-black outline-none focus:outline-none"
                    placeholder="Header"
                    onChange={updateHeader}
                    autoComplete="on"
                    required
                  />

                  <h2 className="font-semibold"> SubHeader </h2>
                  <input
                    type="text"
                    className="form-control w-full text-base py-2 my-4 bg-transparent
               text-black border-b border-black outline-none focus:outline-none"
                    placeholder="SubHeader"
                    onChange={updateSubHeader}
                    autoComplete="on"
                    required
                  />

                  <h2 className="font-semibold"> Document Body </h2>
                  <input
                    type="text"
                    className="form-control w-full text-base py-2 my-4 bg-transparent
               text-black border-b border-black outline-none focus:outline-none"
                    placeholder="Enter your document Body"
                    onChange={updateDocumentBody}
                    autoComplete="on"
                    required
                  />

                  <button
                    className="mt-5 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
                    style={{ backgroundColor: currentColor }}
                  >
                    <span>SUBMIT</span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
        <div className="p-6 rounded bg-[#E6F6FA] m-3">
          <Holiday />
        </div>
      </div>

      {/* {alertMessage && alertType && (
          <ShowAlert message={alertMessage} type={alertType} />
        )} */}

      <Toaster
        position="top-right"
        reverseOrder={false}
        containerClassName="mt-14"
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default LetterHead;
