import React from "react";
import { motion } from "framer-motion";
import { varshasri, venkat, rupavarshini, suresh } from "../../assets/images";
import { Container } from "../../components/Container";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const testimonials = [
  {
    id: 1,
    text: "We are thrilled to announce that one of our recent career have started in 2020 a has successfully completed the develoment program and secured a prestigious position as a Technical Architect at ITC Infotech. This accomplishment reflects the high-quality mentorship and hands-on experience provided during the course, preparing our candidates for real-world challenges",
    author: "Venkatesan Vadivel",
    role: "Technical Architect",
    company: "ITC Infotech",
    profileImage: venkat,
    backgroundColor: "#7eabd8",
  },
  {
    id: 2,
    text: "We are excited to announce that career have started in 2020 one of our senior deveploers has successfully completed the devlopment program and secured a key role as a Senior Software Engineer at Virtusa. This accomplishment highlights the excellence of our development projects curriculum and the readiness of our candidates to thrive in leading organizations.",
    author: "Suresh Murugan",
    role: "Senior Software Engineer",
    company: "Virtusa",
    profileImage: suresh,
    backgroundColor: "#d3d925",
  },
  {
    id: 2,
    text: "We are excited to announce that career have started in 2020  one of our senior developers has successfully completed the development with projects, program and secured a key role as a Senior Software Engineer at Yaazhtech. This accomplishment highlights the excellence of our training curriculum and the readiness of our candidates to thrive in leading organizations.",
    author: "Varshasri",
    role: "Senior Software Engineer",
    company: "Yaazhtech",
    profileImage: varshasri,
    backgroundColor: "#df51bb",
  },
  {
    id: 3,
    text: "We are delighted to share that career have started in 2019  one of our developers has successfully completed the development program, development projects and secured a role as a Senior Software Engineer at Yaazhtech. This achievement underscores the strength of our comprehensive training in preparing candidates for successful careers in top global companies.",
    author: "Rupavarshini Vadivel ",
    role: "Senior Software Engineer",
    company: "Yaazhtech",
    profileImage: rupavarshini,
    backgroundColor: "#51cfdf",
  },
];

const TestimonialSlider = () => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % testimonials.length);
  };

  const goToPrevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + testimonials.length) % testimonials.length
    );
  };

  React.useEffect(() => {
    const interval = setInterval(goToNextSlide, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <h2 className="text-2xl md:text-3xl text-center font-semibold md:mb-12 mb-6">
        Successful Our Developers
      </h2>
      <div className="relative max-w-[1200px] mx-auto h-auto flex flex-col md:flex-row items-center justify-between overflow-hidden px-4 md:px-20 space-y-6 md:space-y-0">
        {/* Arrows for navigation */}
        <button
          onClick={goToPrevSlide}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white p-2 bg-gray-700 rounded-full z-10 md:left-8"
        >
          <FaArrowLeft size={24} />
        </button>

        {/* Testimonial Content */}
        <motion.div
          key={testimonials[currentSlide].id}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
          style={{
            backgroundColor: testimonials[currentSlide].backgroundColor,
          }}
          className="w-full flex flex-col md:flex-row items-center justify-between p-4 md:p-8 rounded-[20px] shadow-lg "
        >
          {/* Text Section */}
          <div className="md:w-1/2 flex flex-col items-center md:items-start space-y-4 md:space-y-6 text-center md:text-left">
            <h2 className="text-xl md:text-4xl font-bold">
              {testimonials[currentSlide].author}
            </h2>
            <h3 className="text-lg md:text-xl font-semibold">
              {testimonials[currentSlide].role}{", "}
              <span className="text-base md:text-lg">
                {testimonials[currentSlide].company}
              </span>
            </h3>
            <p className="text-sm md:text-lg">
              <span className="p-1 text-gray-700">" {testimonials[currentSlide].text} "</span>
            </p>
          </div>

          {/* Image Section */}
          <div className="md:w-1/2 flex justify-center md:justify-end mt-4 md:mt-0">
            <img
              src={testimonials[currentSlide].profileImage}
              alt={testimonials[currentSlide].author}
              className="w-[160px] h-[240px] md:w-[245px] md:h-[342px] rounded-[10px] object-cover"
            />
          </div>
        </motion.div>

        <button
          onClick={goToNextSlide}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white p-2 bg-gray-700 rounded-full z-10 md:right-8"
        >
          <FaArrowRight size={24} />
        </button>

        {/* Navigation Dots */}
        <div className="absolute bottom-4 md:bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`w-3 h-3 rounded-full transition-colors ${
                currentSlide === index ? "bg-white" : "bg-gray-400"
              }`}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default TestimonialSlider;
