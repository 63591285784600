import React, { Component, Suspense } from 'react';
import Lottie from 'react-lottie';
import Loading from '../loading/Loading';

export default class DisplayLottie extends Component {
  render() {
    const { animationData } = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
    };

    return (
      <Suspense fallback={<Loading />}>
        <Lottie options={defaultOptions} isClickToPauseDisabled width={480}  />
      </Suspense>
    );
  }
}
