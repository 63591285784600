import React from 'react';

const LeadershipCard = ({ name, position, imageUrl, color, onReadMore }) => {
  return (
    <div className="w-full sm:w-[342px] h-full cursor-pointer transition-transform transform hover:-translate-y-2 shadow-lg rounded-lg overflow-hidden bg-white">
      <div className="relative w-full h-[200px] sm:h-[300px]" style={{ backgroundColor: color }}>
        <img src={imageUrl} alt={name} className="w-full h-full object-cover" />
      </div>
      <div className="p-4">
        <h3 className="text-gray-900 font-bold text-xl">{name}</h3>
        <p className="text-gray-600">{position}</p>
        <div className="mt-4">
          <button
            onClick={onReadMore}
            className="flex items-center text-primary-500 hover:text-blue-700"
          >
            Read more
            <span className="ml-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeadershipCard;
