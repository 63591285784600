import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Group,
  PdfExport,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { Link, useLocation, NavLink } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../../../../contexts/StateContext";
import { getClients } from "../../../../../api/AdminApi";
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import { generateInvoice, retrieveUser } from "../../../../../api/commonApi";
import { Button } from "../../../../../components";
import ActionViewClientDetail from "./ActionViewClientDetail";
import { useNavigate } from "react-router-dom";
import ActionTrainingDetail from "./ActionTrainingDetail";

const TrainingInvoiceDetails = () => {
  const navigate = useNavigate();
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const selectionsettings = { persistSelection: true };
  const [showAction, setAction] = React.useState(false);
  //const location = useLocation();
  //const { clientUserName } = location.state;

  const toolbarOptions = ["Search"];
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: "Top",
  };
  const editparams = { params: { popupHeight: "300px" } };
  const validationRule = { required: true };
  const emailRules = { required: true, text: true };
  const pageSettings = { pageCount: 5 };
  const selectionSettings = { persistSelection: true };
  const format = { type: "dateTime", format: "M/d/y hh:mm a" };
  const droplist = [
    { text: "Top", value: "Top" },
    { text: "Bottom", value: "Bottom" },
  ];

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [clientDetail, setClientDetail] = useState({
    clientName: "",
    clientEmailId: "",
    clientContactNo: "",
    clientStatus: "",
    clientRole: "",
  });
  const [data, setData] = useState([]);
  const updateSearchText = (event) => {
    setSearchText(event.target.value);
  };
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  const splitByT = (zonedDateTimeString) => {
    let dates = [];
    dates = zonedDateTimeString.split("T");

    return dates[0] ? dates[0] : "NA";
  };
  let applicantsList = [];
  useEffect(() => {
    document.title = "Invoice management";
    loadData();
  }, []);
  const loadData = () => {
    //alert("userEmail"+JSON.stringify(clientUserName))
    retrieveUser()
      .then((res) => {
        const subscriptionList = res.data.subscription;
        let req = clientDetail;
        req.clientName = res.data.name;
        req.clientEmailId = res.data.email;
        req.clientContactNo = res.data.mobile;
        req.clientStatus = res.data.status;
        req.clientRole = res.data.role;
        setClientDetail(req);
        const constructPaymentDataList = [];
        const { name } = res.data;
        const userName = res.data.email;
        if (
          subscriptionList !== undefined &&
          subscriptionList !== null &&
          subscriptionList.length > 0
        ) {
          for (let i = 0; i < res.data.subscription.length; i++) {
            // alert("subscription -" + JSON.stringify(subscriptionList[i]));
            const payment = subscriptionList[i].paymentList; // list
            if (
              payment !== undefined &&
              payment !== null &&
              payment.length > 0
            ) {
              for (let j = 0; j < payment.length; j++) {
                const paymentData = {
                  projectRequirement: "",
                  dueDate: "",
                  paymentBillingFrom: "",
                  paymentBillingTo: "",
                  totalBillingDay: "",
                  totalBillingHours: "",
                  costPerDay: "",
                  subscriptionType: "",
                  subscriptionFor: "",
                  costExcludingGstPay: "",
                  costIncludingGstPay: "",
                  costIncludingGstPayMinusTDS: "",
                  paymentStatus: "",
                  subscriptionId: "",
                  paymentId: "",
                  userName: "",
                  subscriptionId: "",
                  subscriptionName: "",
                  subscriptionCost: "",
                  invoiceDate: "",
                  paidSoFar: "",
                  employeeEmailIdList: [],
                  gst: "",
                };
                if (payment[j].paymentStatus !== "paymentReceived") {
                  paymentData.subscriptionId =
                    subscriptionList[i].subscriptionId;
                  paymentData.subscriptionCost =
                    subscriptionList[i].subscriptionCost;
                  paymentData.employeeEmailIdList =
                    subscriptionList[i].employeeEmailIdList;
                  paymentData.subscriptionFor =
                    subscriptionList[i].subscriptionFor;
                  paymentData.subscriptionType =
                    subscriptionList[i].subscriptionType;
                  paymentData.subscriptionName =
                    subscriptionList[i].subscriptionName;
                  paymentData.subscriptionId =
                    subscriptionList[i].subscriptionId;
                  paymentData.projectRequirement =
                    subscriptionList[i].projectRequirement;
                  paymentData.costPerDay = subscriptionList[i].subscriptionCost;
                  paymentData.dueDate = payment[j].dueDate;
                  paymentData.paidSoFar = payment[j].paidSoFar;
                  paymentData.invoiceDate = payment[j].invoiceDate;
                  paymentData.paymentBillingFrom =
                    payment[j].paymentBillingFrom;
                  paymentData.paymentBillingTo = payment[j].paymentBillingTo;
                  paymentData.costExcludingGstPay =
                    payment[j].costExcludingGstPay;
                  paymentData.costIncludingGstPay =
                    payment[j].costIncludingGstPay;
                  paymentData.gst = Math.round(
                    payment[j].costIncludingGstPay -
                      payment[j].costExcludingGstPay
                  );
                  paymentData.costIncludingGstPayMinusTDS = Math.round(
                    payment[j].costIncludingGstPay -
                      payment[j].costExcludingGstPay / 10
                  );
                  paymentData.paymentId = payment[j].paymentId;
                  paymentData.totalBillingDay = payment[j].totalBillingDay;
                  paymentData.totalBillingHours = payment[j].totalBillingHours;
                  paymentData.paymentStatus = payment[j].paymentStatus;
                  paymentData.userName = userName;
                  constructPaymentDataList.push(paymentData);
                }
              }
              constructPaymentDataList.sort((a, b) =>
                a.dueDate > b.dueDate ? 1 : b.dueDate > a.dueDate ? -1 : 0
              );
            } else {
              setLoading(false);
            }
          }
          setData(constructPaymentDataList);
          // alert("paymentDataList"+JSON.stringify(this.state.paymentDataList))
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("Sorry! Something went wrong. Please try again!");
        }
        setLoading(false);
        console.log(error);
      });
  };
  const handleClickBack = () => {
    navigate("/");
  };
  {
    /** const dueDateStatusTemplate = ((props) => {
      
      const [userName, setUserName] = useState('');
      const [userEmail, setUserEmail ] = useState('');
      const [leaveStatus, setLeaveStatus] = useState([]);
     useEffect(()=> {
      showDetail();
     }, []);
      const showDetail = () => {
        setUserName(props.name);
        setUserEmail(props.email); 
        setLeaveStatus(props.leaveStatus);  
      };
      return (
         
        <>
        {dueDate<0 ? (
                  <span className="badge bg-deep-orange-800 p-1 text-white rounded-lg">Irregular</span>
                ) : (
                  <span className="badge bg-green-700 p-1  text-white rounded-lg">Regular</span>
                )}
      </>
         
      )
    })*/
  }
  const actionTemplate = (props) => {
    const paymentData = {
      projectRequirement: props.projectRequirement,
      dueDate: props.dueDate,
      paymentBillingFrom: props.paymentBillingFrom,
      paymentBillingTo: props.paymentBillingTo,
      totalBillingDay: props.totalBillingDay,
      totalBillingHours: props.totalBillingHours,
      costPerDay: props.subscriptionCost,
      subscriptionType: props.subscriptionType,
      subscriptionFor: props.subscriptionFor,
      costExcludingGstPay: props.costExcludingGstPay,
      costIncludingGstPay: props.costIncludingGstPay,
      costIncludingGstPayMinusTDS:
        props.costIncludingGstPay - props.costIncludingGstPay / 10,
      paymentStatus: props.paymentStatus,
      subscriptionId: props.subscriptionId,
      paymentId: props.paymentId,
      userName: props.userName,
      paidSoFar: props.paidSoFar,
      subscriptionId: props.subscriptionId,
      subscriptionName: props.subscriptionName,
      subscriptionCost: props.subscriptionCost,
      employeeEmailIdList: props.employeeEmailIdList,
    };

    return <ActionTrainingDetail subscription={paymentData} />;
  };
  return (
    <div
      className={
        currentMode === "Dark"
          ? "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-dark-gradient-r"
          : "m-2 md:m-10 mt-12 p-2 md:p-5 rounded-3xl bg-light-gradient-r"
      }
    >
      <div className="p-2 m-2">
        <h2
          className="p-1 md:text-2xl float-left"
          style={{ color: currentColor }}
        >
          Invoice & Payment Status
        </h2>
        <button
          className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          style={{ backgroundColor: currentColor }}
          onClick={() => handleClickBack()}
        >
          <span>back</span>
        </button>
      </div>

      <GridComponent
        dataSource={data}
        enableHover={true}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionSettings}
        toolbar={toolbarOptions}
        editSettings={editSettings}
        allowFiltering={false}
        allowGrouping={true}
        allowPdfExport={true}
        allowSorting
      >
        <ColumnsDirective>
          {/*<ColumnDirective field='probation' headerText='Probation' width='150' isPrimaryKey={true} textAlign="Center" template={dialogTemplate} />*/}
          <ColumnDirective
            field="action"
            headerText="Action"
            width="100"
            textAlign="Center"
            template={actionTemplate}
          />
          <ColumnDirective
            field="paymentStatus"
            headerText="status"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="paidSoFar"
            headerText="paidSoFar"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="dueDate"
            headerText="Due Date"
            width="100"
            textAlign="Center"
          />
          {/**<ColumnDirective field='dueDate' headerText='dueDate' width='100'  textAlign="Center" template={dueDateStatusTemplate} /> */}
          <ColumnDirective
            field="invoiceDate"
            headerText="Invoice Date"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="costIncludingGstPay"
            headerText="Cost + GST"
            width="100"
            textAlign="Center"
          />
          {clientDetail.clientEmailId === "gouri@fastfashionsolutions.com" ? (
            <ColumnDirective
              field="costIncludingGstPayMinusTDS"
              headerText="Excl.TDS(10%)"
              width="100"
              textAlign="Center"
            />
          ) : null}
          <ColumnDirective
            field="gst"
            headerText="Gst (18%)"
            width="100"
            textAlign="Center"
          />
          <ColumnDirective
            field="paymentId"
            headerText="Id"
            width="100"
            textAlign="Center"
          />

          {/*
        <ColumnDirective
          field="subscriptionId"
          headerText="Id"
          width="100"
          textAlign="Center"
        />
        <ColumnDirective field='projectRequirement' headerText='Project' width='100'  textAlign="Center" />
        
        <ColumnDirective field='billingPeriodFrom' headerText='Period From' width='120'  textAlign="Center" />
        <ColumnDirective field='billingPeriodTo' headerText='Period To' width='100'  textAlign="Center" />
        <ColumnDirective field='totalBillingDay' headerText='Quantity' textAlign="Center"   width='100' />
        <ColumnDirective field='totalBillingHours' headerText='Billing Hours' width='100'  textAlign="Center" />
        <ColumnDirective field='costPerDay' headerText='Cost per Quantity' width='100'  textAlign="Center" />
                  <ColumnDirective field='costExcludingGstPay' headerText='Total Cost' width='100'  textAlign="Center" />*/}

          {/* <ColumnDirective field='subscribedFrom' headerText='Download Invoice' width='100' template={invoiceTemplate}  textAlign="Center" />
        <ColumnDirective field='subscribedFrom' headerText='Pay Button' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Edit Invoice' width='100'  textAlign="Center" template={invoiceTemplate} />
        <ColumnDirective field='subscribedFrom' headerText='Update Payment Status' width='100'  textAlign="Center" />
                */}

          {/* {applicantsGrid.map((item, index) => (
          <ColumnDirective key={index} {...item} />
       ))}*/}
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Selection,
            Toolbar,
            Edit,
            Sort,
            Filter,
            Group,
            PdfExport,
          ]}
        />
      </GridComponent>

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default TrainingInvoiceDetails;
