import React, { useState } from 'react';
import LeadershipCard from './LeadershipCard';
import LeadershipPopup from './LeadershipPopup';
import { esther, removebgRajappan, rupavarshini, varshasri, venkat, rajappan } from '../../../assets/images';
import { Container } from '../../../components/Container';

const ExecutiveTeam = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  // Function to open the popup
  const handleReadMore = (member) => {
    setSelectedMember(member);
  };

  // Function to close the popup
  const closePopup = () => {
    setSelectedMember(null);
  };

  const teamMembers = [
    {
      name: 'Venkatesan Vadivel',
      position: 'VP of Product (Product Manager)',
      imageUrl: venkat,
      color: 'pink',
      about: 'Venkatesan is the VP of Product and brings a wealth of experience in product management.',
      linkedin: 'https://www.linkedin.com/in/venkatesan-vadivel/'
    },
    {
      name: 'Esther Jentia',
      position: 'Tech Lead',
      imageUrl: esther,
      color: 'lightblue',
      about: 'Esther is the Tech Lead and has led multiple successful software projects.',
      linkedin: 'http://www.linkedin.com/in/esther-jenita'
    },
    {
      name: 'Varshasri',
      position: 'Senior Software Engineer',
      imageUrl: varshasri,
      color: 'lightgreen',
      about: 'Varshasri is a Senior Software Engineer with over 5 years of experience in software development.',
      linkedin: 'http://www.linkedin.com/in/varsha-sri-bb4034146'
    },
    {
      name: 'Rupavarshni',
      position: 'Senior Software Engineer',
      imageUrl: rupavarshini,
      color: 'lightcoral',
      about: 'Rupavarshni is a senior engineer with expertise in building scalable web applications.',
      linkedin: 'http://www.linkedin.com/in/rupa-varshni'
    },
    {
      name: 'Rajappan',
      position: 'Software Engineer',
      imageUrl: removebgRajappan,
      color: 'pink',
      about: 'Rajappan is a Software Engineer passionate about coding and solving complex problems.',
      linkedin: 'http://www.linkedin.com/in/rajappan-sellamuthu-5742b2204'
    },
    {
      name: 'Sarathi',
      position: 'Tech Lead',
      imageUrl: rajappan,
      color: 'lightblue',
      about: 'Sarathi is a Tech Lead with extensive experience in leading agile development teams.',
      linkedin: 'https://www.linkedin.com/in/sarathi'
    },
    {
      name: 'Rajeshwari',
      position: 'Tech Lead',
      imageUrl: varshasri,
      color: 'lightgreen',
      about: 'Rajeshwari has a deep understanding of modern software architecture and leads multiple tech teams.',
      linkedin: 'https://www.linkedin.com/in/rajeshwari'
    },
  ];

  const hrMembers = [
    {
      name: 'Soundarya',
      position: 'Senior Human Resource(HR)',
      imageUrl: venkat,
      color: 'pink',
      about: 'Soundarya is the Senior HR manager and brings a wealth of experience in HR management.',
      linkedin: 'https://www.linkedin.com/in/soundarya'
    },
    {
      name: 'Rekha',
      position: 'Human Resource(HR)',
      imageUrl: esther,
      color: 'lightblue',
      about: 'Rekha is an HR expert who manages the company’s recruitment and employee engagement.',
      linkedin: 'https://www.linkedin.com/in/rekha'
    },
    {
      name: 'Sumi',
      position: 'BDM',
      imageUrl: varshasri,
      color: 'lightgreen',
      about: 'Sumi is the Business Development Manager with expertise in expanding business operations.',
      linkedin: 'https://www.linkedin.com/in/sumi'
    },
    {
      name: 'Darshni',
      position: 'BDM',
      imageUrl: rupavarshini,
      color: 'lightcoral',
      about: 'Darshni is responsible for building business relationships and expanding the market.',
      linkedin: 'https://www.linkedin.com/in/darshni'
    },
  ];

  return (
    <Container>
      <h2 className="text-2xl md:text-3xl text-center font-semibold md:mb-12 mb-6">Product Team</h2>
      <div className="flex flex-wrap justify-center gap-8 py-16 bg-gray-100">
        {teamMembers.map((member, index) => (
          <LeadershipCard
            key={index}
            name={member.name}
            position={member.position}
            imageUrl={member.imageUrl}
            color={member.color}
            onReadMore={() => handleReadMore(member)} // Pass function to open popup
          />
        ))}

        {/* Popup Modal */}
        {selectedMember && <LeadershipPopup member={selectedMember} closePopup={closePopup} />}
      </div>
      <h2 className="text-2xl md:text-3xl text-center font-semibold md:mb-12 my-6">HR and Management</h2>
      <div className="flex flex-wrap justify-center gap-8 py-16 bg-gray-100">
        {hrMembers.map((member, index) => (
          <LeadershipCard
            key={index}
            name={member.name}
            position={member.position}
            imageUrl={member.imageUrl}
            color={member.color}
            onReadMore={() => handleReadMore(member)} // Pass function to open popup
          />
        ))}

        {/* Popup Modal */}
        {selectedMember && <LeadershipPopup member={selectedMember} closePopup={closePopup} />}
      </div>
    </Container>
  );
};

export default ExecutiveTeam;
