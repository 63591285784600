import React, { useState } from "react";
import {
  jobContent,
  training,
  jobRequirements,
} from "../../data/content/PageContent";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
} from "@mui/material";

const Training = () => {
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const navigate = useNavigate();

  const [showUiDeveloper, setUiDeveloper] = useState(false);
  const [showFullStackDeveloper, setFullStackDeveloper] = React.useState(false);
  const [showBackendDeveloper, setBackendDeveloper] = React.useState(false);
  const [showMobileDeveloper, setMobileDeveloper] = React.useState(false);
  const [showCamundaDeveloper, setCamundaDeveloper] = React.useState(false);
  const [showDevopsDeveloper, setDevopsDeveloper] = React.useState(false);
  const [showDigitalMarketing, setDigitalMarketing] = React.useState(false);
  const [showPythonDeveloper, setPythonDeveloper] = React.useState(false);
  const [showGoLangDeveloper, setGoLangDeveloper] = React.useState(false);
  const [showHr, setHr] = React.useState(false);
  const [showApi, setApi] = React.useState(false);
  const [showSqlDeveloper, setSqlDeveloper] = React.useState(false);

  const toggleUiDeveloper = () => {
    setUiDeveloper(!showUiDeveloper);
  };

  const toggleFullStackDeveloper = () => {
    setFullStackDeveloper(!showFullStackDeveloper);
  };

  const toggleBackendDeveloper = () => {
    setBackendDeveloper(!showBackendDeveloper);
  };

  const toggleMobileDeveloper = () => {
    setMobileDeveloper(!showMobileDeveloper);
  };

  const toggleCamundaDeveloper = () => {
    setCamundaDeveloper(!showCamundaDeveloper);
  };

  const toggleDevopsDeveloper = () => {
    setDevopsDeveloper(!showDevopsDeveloper);
  };

  const toggleDigitalMarketing = () => {
    setDigitalMarketing(!showDigitalMarketing);
  };

  const togglePythonDeveloper = () => {
    setPythonDeveloper(!showPythonDeveloper);
  };

  const toggleGoLangDeveloper = () => {
    setGoLangDeveloper(!showGoLangDeveloper);
  };

  const toggleHr = () => {
    setHr(!showHr);
  };

  const toggleApi = () => {
    setApi(!showApi);
  };

  const toggleSqlDeveloper = () => {
    setSqlDeveloper(!showSqlDeveloper);
  };

  return (
    <>
      <div>
        <div className="text-center mt-2">
          <h2
            className="text-3xl font-bold uppercase mb-2"
            style={{ color: currentColor }}
          >
            {" "}
            {training.title1}{" "}
          </h2>
          <div class="contact-section">
            <span class="contact-info text-xl font-bold uppercase mb-2">
              <p>
                <a
                  class="whatsapp-link"
                  href="https://wa.me/918072488209"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp
                    class="w-8 h-8 inline-block -mt-1 mr-2 "
                    style={{ background: "green", color: "white" }}
                  />
                  Whatsapp us for quick apply +91 8072488209
                </a>
              </p>
            </span>
          </div>
          <marquee
            width="100%"
            direction="left"
            height="40px"
            className="text-dark"
          >
            <h4
              className="text-2xl font-bold uppercase mt-2"
              style={{ color: currentColor }}
            >
              {" "}
              {training.title2}
            </h4>
          </marquee>
        </div>
        <div className="p-2 mt-5">
          <div className=" lg:flex lg:flex-row  sm:flex sm:flex-col justify-center sm:items-center mr-14">
            <div className=" w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.uiDeveloper}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 60,000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 30000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white font-bold uppercase px-6 py-2  mt-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={toggleUiDeveloper}
                  >
                    Proceed
                  </button>
                </div>

                {showUiDeveloper && (
                  <Dialog open={showUiDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "36000",
                                subscriptionFor: "UI Developer",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>
            <div className=" w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.fullStack}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 90,000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 45000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white mt-2 font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={toggleFullStackDeveloper}
                  >
                    Proceed
                  </button>
                </div>

                {showFullStackDeveloper && (
                  <Dialog open={showFullStackDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "54000",
                                subscriptionFor: "Full Stack Developer",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>	
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>
            <div className=" w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.backend}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 60,000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 30000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white mt-2 font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={toggleBackendDeveloper}
                  >
                    Proceed
                  </button>
                </div>

                {showBackendDeveloper && (
                  <Dialog open={showBackendDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "36000",
                                subscriptionFor: "Backend Developer",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>	
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="p-2 mt-5">
          <div className=" lg:flex lg:flex-row justify-center sm:flex sm:flex-col sm:items-center mr-14">
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.mobile}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 60000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 30000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white mt-2 font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={toggleMobileDeveloper}
                  >
                    Proceed
                  </button>
                </div>

                {showMobileDeveloper && (
                  <Dialog open={showMobileDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "72000",
                                subscriptionFor: "Mobile App Developer (Android/IOs)",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>	
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.camunda}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 60000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 30000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white mt-2 font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={toggleCamundaDeveloper}
                  >
                    Proceed
                  </button>
                </div>

                {showCamundaDeveloper && (
                  <Dialog open={showCamundaDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "72000",
                                subscriptionFor: "CamundaDeveloper",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>	
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>
                <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.devops}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 60,000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 30000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white mt-2 font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={toggleDevopsDeveloper}
                  >
                    Proceed
                  </button>
                </div>

                {showDevopsDeveloper && (
                  <Dialog open={showDevopsDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "36000",
                                subscriptionFor: "AWS Cloud Dev Ops Engineer",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>	
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="p-2 mt-5">
          <div className=" lg:flex lg:flex-row  sm:flex sm:flex-col sm:items-center justify-center mr-14">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.digitalMarketing}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 60,000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 30000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white mt-2 font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={toggleDigitalMarketing}
                  >
                    Proceed
                  </button>
                </div>

                {showDigitalMarketing && (
                  <Dialog open={showDigitalMarketing} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "36000",
                                subscriptionFor: "Digital Marketing Expert",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>	
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
             <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.pythonDeveloper}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 60,000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 30000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white mt-2 font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={togglePythonDeveloper}
                  >
                    Proceed
                  </button>
                </div>

                {showPythonDeveloper && (
                  <Dialog open={showPythonDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "36000",
                                subscriptionFor: "Python Developer",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>	
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>
            <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.golangDeveloper}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 60,000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 30000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white mt-2 font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={toggleGoLangDeveloper}
                  >
                    Proceed
                  </button>
                </div>

                {showGoLangDeveloper && (
                  <Dialog open={showGoLangDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "36000",
                                subscriptionFor: "GoLang Developer",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>	
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="p-2 mt-5">
          <div className=" lg:flex lg:flex-row  justify-center sm:flex sm:flex-col sm:items-center mr-14">
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
             <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.hrManager}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 30,000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 15000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white mt-2 font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={toggleHr}
                  >
                    Proceed
                  </button>
                </div>

                {showHr && (
                  <Dialog open={showHr} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "18000",
                                subscriptionFor: "HR Manager",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>	
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
            <br></br>
            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
             <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.apiTesting}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 60,000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 30000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white mt-2 font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={toggleApi}
                  >
                    Proceed
                  </button>
                </div>

                {showApi && (
                  <Dialog open={showApi} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "36000",
                                subscriptionFor: "API Test Engineer",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>	
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
                
              </div>
            </div>
            <br></br>

            {/* <div
              className={
                currentMode === "Dark"
                  ? "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-dark-gradient-r"
                  : "w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-light-gradient-r"
              }
            > */}
             <div className="w-full ml-10  lg:w-1/4 p-3 rounded-lg bg-white">
              <div className="p-2 mt-5">
                <h3 className="font-semibold text-lg">
                  <span style={{ color: currentColor }}>
                    {jobContent.sqlDeveloper}
                  </span>
                </h3>

                <p className="px-3 text-sm  bg-backgroundColor">
                  {" "}
                  Work From Home
                </p>
                <li className="px-2 text-sm  bg-backgroundColor"> Training</li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Level Clearance
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Client Interview
                </li>
                <li className="px-2 text-sm  bg-backgroundColor">
                  {" "}
                  Job At Yaazhtech
                </li>
                <div className="flex flex-row justify-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <p className="px-3 text-sm  bg-backgroundColor">
                        <b>
                          {" "}
                          <span
                            style={{
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }}
                          >
                            {" "}
                            ₹ 60,000{" "}
                          </span>{" "}
                          <span>/ </span>₹ 30000 Only{" "}
                        </b>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  <button
                    className="text-white mt-2 font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    style={{ backgroundColor: currentColor }}
                    onClick={toggleSqlDeveloper}
                  >
                    Proceed
                  </button>
                </div>

                {showSqlDeveloper && (
                  <Dialog open={showSqlDeveloper} fullWidth maxWidth="sm">
                    <DialogTitle>
                      <h2 className="card-title text-center text-lg font-semibold">
                        Terms and Conditions
                      </h2>
                    </DialogTitle>
                    <DialogContent>
                    <div className="flex items-center justify-between mt-4 border-t border-solid border-blueGray-200">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                          <div>
                            <Link
                              to={{
                                pathname: "/careersRegister",
                              }}
                              state={{
                                subscriptionCost: "36000",
                                subscriptionFor: "SQL Developer",
                                subscriptionType: "TRAINING-hire",
                              }}
                              exact
                            >
                              <Button
                                variant="contained"
                                className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                                style={{ backgroundColor: currentColor }}
                              >
                                Apply
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="text-white font-bold uppercase px-4 py-2 text-sm rounded"
                              style={{ backgroundColor: currentColor }}
                              component={Link}
                              to="/trainingAndHiring"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        </div>
                      <Paper className="p-4 max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blueGray-400 scrollbar-track-blueGray-100 scrollbar-w-3 scrollbar-h-3">
                        <p className="pages-content-text text-base ml-2 md:ml-4 mb-4">
                          <b>{training.trainingGiven}</b>
                        </p>
                        <ul className="list-disc ml-2 md:ml-4">
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">Training Phase:</span>{" "}
                            {training.levels}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Internship Program:
                            </span>{" "}
                            {training.intern}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Client Intern Opportunity:
                            </span>{" "}
                            {training.client}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Full-Time Employment:
                            </span>{" "}
                            {training.fullTime}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Recognition of Efforts:
                            </span>{" "}
                            {training.certificate}
                          </li>
                          <li className="pages-content-text text-sm mb-2">
                            <span className="font-bold">
                              Trial Period Assurance:
                            </span>{" "}
                            {training.freeTraining}
                          </li>
                        </ul>	
                      </Paper>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="mt-5 ml-28 w-50 rounded-lg px-7 pb-2.5 pt-3 text-sm text-white"
        style={{ backgroundColor: currentColor }}
      >
        <Link
          to={{
            pathname: "/trainingAndHiring",
          }}
        >
          <span>Cancel</span>
        </Link>
      </button>
    </>
  );
};

export default Training;